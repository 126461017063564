import React from 'react';
import {GETTING_STARTED} from '../../constants/constants';
import js from '../../res/js.png';
import java from '../../res/java.png';
import python from '../../res/python.png';


function GettingStarted() {
  return (
    <div className="getting-started">
      <h2>How to use it?</h2>
      <hr/>
      <div className="row">
        <div className="column">
          <div className="language" style={{paddingBottom: "20px"}}>
            <h3 className="language-title">JavaScript&nbsp;&nbsp;</h3>
            <img src={js} alt="Send a GET request in JavaScript"/>
          </div>
          <div className="getting-started-first">
            <p>In case you want to request a particular object from an API and this object already exists and you know an object ID of it, you can simply send a GET request to fetch it.</p>
            <pre>
              <code>
                <p>
                {GETTING_STARTED[0].code}
                </p>
              </code>
            </pre>
            <p>In the example above we are fetching the object with id = 4. Response from an API would look like this:</p>
            <pre>
              <code>
                <p>
                {GETTING_STARTED[0].response1}
                </p>
              </code>
            </pre>
            <p>In order to fetch another object, you can always change the object ID which comes as part of your GET request URL. For example, changing the object id from 4 to 5 will return you the following response:</p>
            <pre>
              <code>
                <p>
                {GETTING_STARTED[0].response2}
                </p>
              </code>
            </pre>
          </div>
        </div>
        <div className="column">
          <div className="language" style={{paddingBottom: "20px"}}>
            <h3 className="language-title">Java&nbsp;&nbsp;</h3>
            <img src={java} alt="Send a POST request in Java"/>
          </div>
          <p>In case you want to add a new object using POST:</p>
          <div>
            <pre>
              <code>
                <p>
                  {GETTING_STARTED[1].code}
                </p>
              </code>
            </pre>
            <p>Data from this request would get stored in the database and API would reply to you with a response that would look like this:</p>
            <pre>
              <code>
                <p>
                {GETTING_STARTED[1].response}
                </p>
              </code>
            </pre>
            <p>Keep in mind that every time you execute this piece of code, you will create a new object with the same data but a new object id. To store unique objects, update the body of your request.</p>
            <p>After executing a POST request, you can access the created object via GET request by providing the corresponding object id as part of a GET request url.</p>
          </div>
        </div>
        <div className="column">
          <div className="language" style={{paddingBottom: "20px"}}>
            <h3 className="language-title">Python&nbsp;&nbsp;</h3>
            <img src={python} alt="Send a PUT request in Python"/>
          </div>
          <p>In case you want to update the price attribute of an existing object below from 120 to 135 and to add an extra color attribute using PUT:</p>
          <div>
            <pre>
              <code>
                <p>
                  {GETTING_STARTED[2].currentDataObject}
                </p>
              </code>
            </pre>
            <p>Pass a complete representation of the updated object as part of a body and the object id as part of a request url.</p>
            <pre>
              <code>
                <p>
                  {GETTING_STARTED[2].code}
                </p>
              </code>
            </pre>
            <p>Response would look like this:</p>
            <pre>
              <code>
                <p>
                {GETTING_STARTED[2].response}
                </p>
              </code>
            </pre>
          </div>
        </div>        
      </div>
    </div>
  )
}

export default GettingStarted