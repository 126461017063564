import React, {useEffect, useRef} from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function SideMenu (props) {

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to top of page
  };

  const handleClick = useRef((event) => {
    const element = document.querySelector('.side-menu.open');
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const windowWidth = window.innerWidth || document.documentElement.clientWidth;
      const isVisible = (elementRect.right <= windowWidth) && ((elementRect.right + elementRect.width) >= 0);
      if (isVisible && !element.isSameNode(event.target)) {
        props.onClose();
      }
    }
  });

  useEffect(() => {
    document.addEventListener('click', handleClick.current);

    return () => {
      document.removeEventListener('click', handleClick.current);
    };
  }, [handleClick]);

  return (
    <div id='menu' className={`side-menu ${props.isOpen ? 'open' : ''} ${props.blackSideMenu}`}>
      <ul>
        <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
        <li><Link to="/rest-fundamentals#rest">Rest Fundamentals</Link></li>
        <li><Link to="/support-us#support">Support Us</Link></li>
      </ul>
      <button aria-label="Close" className="close-btn" onClick={props.onClose}>Close</button>
    </div>
  );
};  

export default SideMenu;
