import React from 'react'
import MyHeader from './../MyHeader.js';
import Menu from '../Menu/Menu.js';
import Footer from './../Footer.js';
import './../../App.css';
import './styles.scss';
import SupportUsButton from './SupportUsButton';
import useScreenWidth from '../../hooks/useScreenWidth.js';
import {SMALL_SCREEN_WIDTH} from '../../constants/constants';
import MobileMenu from '../Menu/MobileMenu.js';
import CookiesPopup from '../Cookies/CookiesPopup.js';

function SupportUs() {

    const widthSize = useScreenWidth();

    const isSnap = navigator.userAgent === 'ReactSnap'

    return (      
        <div className="body">
            {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
            <MyHeader/>
            <h2>Support Us</h2>
            <hr/>
            <div className="support-us-text">
                <p id="support">We believe in providing a valuable REST API service and offering learning materials for free to our users. However, maintaining and improving our services requires resources, and your generous contributions can make a significant difference.</p>
                <p>Every donation, no matter the size, helps us cover the costs of servers, maintenance, and development. Your support enables us to continue offering our REST API for free and even enhance its features for a better user experience.</p>
                {!isSnap && <SupportUsButton/>}
                <br/><br/><br/>
            </div>
            <Footer/>
            <CookiesPopup widthSize={widthSize}/>
        </div>
    );
}

export default SupportUs