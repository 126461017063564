import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

export const FAQ  = () => {

  return (
      <div className="faq">
        <h2>FAQ</h2>
        <hr/>
        <div>
          <br/>
          <div className="faq-question">
            <Link to="/rest-fundamentals#rest-api">
              <b>&#9658;&nbsp;&nbsp;What is REST API?</b>
            </Link>
          </div>
          <div className="faq-question">
            <Link to="/rest-fundamentals#get">
              <b>&#9658;&nbsp;&nbsp;What is GET Request?</b>
            </Link>
          </div>
          <div className="faq-question">
            <Link to="/rest-fundamentals#post">
              <b>&#9658;&nbsp;&nbsp;What is POST Request?</b>
            </Link>
          </div>
          <div className="faq-question">
            <Link to="/rest-fundamentals#put">
              <b>&#9658;&nbsp;&nbsp;What is PUT Request?</b>
            </Link>
          </div>
          <div className="faq-question">
            <Link to="/rest-fundamentals#delete">
              <b>&#9658;&nbsp;&nbsp;What is DELETE Request?</b>
            </Link>
          </div>
          <div className="faq-question">
            <Link to="/rest-fundamentals#patch">
              <b>&#9658;&nbsp;&nbsp;What is PATCH Request?</b>
            </Link>
          </div>
        </div>
      </div>
    )
}

export default FAQ