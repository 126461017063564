import { useState } from 'react';
import './styles.scss';
import {MOBILE_SCREEN_WIDTH as MOBILE_SCREEN_WIDTH} from '../../constants/constants';

function Toggle({ checked, onChange, essential, widthSize}) {
  const [isChecked, setIsChecked] = useState(checked);

  function handleClick(essential) {
    if (!essential) {
      setIsChecked(!isChecked);
      onChange(!isChecked);
    }
  }

  return (
    <div className="toggle-box" onClick={() => handleClick(essential)} style={{background: isChecked ? '#4CAF50' : '#ccc', opacity: essential ? 0.5 : 1}}>
        { widthSize < MOBILE_SCREEN_WIDTH ? (
          <div className="toggle-box-ball"
            style={{transform: isChecked ? 'translateX(0.7em)' : 'translateX(0px)'}}
          />
        ) : (
          <div className="toggle-box-ball"
            style={{transform: isChecked ? 'translateX(1.1em)' : 'translateX(0px)'}}
          />
        )
        }
    </div>
  );
}
export default Toggle;