import React, { useState, useEffect } from 'react';
import './styles.scss';
import { HashLink as Link } from 'react-router-hash-link';

function Menu() {

  const [sticky, setSticky] = useState('');

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to top of page
  };

  const [scrollPos, setScrollPos] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPos > 50) {
      setSticky('hide');
    }
    
    if (scrollPos < prevScrollPos) {
      const headerElement = document.querySelector('.app-header');
      if (headerElement.getBoundingClientRect().bottom < 0) {
          setSticky('sticky');
      } else if (headerElement.getBoundingClientRect().bottom < 450) {
          setSticky('hide');
      } else {
          setSticky('');
      }
    }
    setPrevScrollPos(scrollPos);
  }, [scrollPos]);

  const menuClass = sticky;

  return (
    <div className='header-menu'>
      <nav className={`menu ${menuClass}`}>
        <div className="logo">
            <a href="/">
              <span className='braces'>&#123;</span>
              <span className="rest-api">REST-API</span>
              <span className='braces'>&#125;</span>
              <span className="ready-to-use">
              &nbsp;READY TO USE
              </span>
            </a>
        </div>
        <Link to="/" onClick={handleLinkClick}>Home</Link>
        <Link to="/rest-fundamentals#rest">Rest Fundamentals</Link>
        <Link to="/support-us#support">Support Us</Link>
      </nav>
    </div>
  );
};

export default Menu;
