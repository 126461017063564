import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './styles.scss';
import { HashLink as Link } from 'react-router-hash-link';
import ManageCookies from './ManageCookies.js';
import {MOBILE_SCREEN_WIDTH as MOBILE_SCREEN_WIDTH} from '../../constants/constants';


function CookiesPopup(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [openManageCookiesModal, setOpenManageCookiesModal] = useState(false);
    const marketingConsent = localStorage.getItem('RESTFUL_API_DEV_MARKETING_COOKIES_ALLOWED');
    const analyticsConsent = localStorage.getItem('RESTFUL_API_DEV_ANALYTICS_COOKIES_ALLOWED');

    useEffect(() => {
      setTimeout(() => {
        if (marketingConsent === null || analyticsConsent === null) {
          setIsOpen(true);
        }
      }, 5500);

    }, []);

    function acceptCookies() {
        localStorage.setItem('RESTFUL_API_DEV_MARKETING_COOKIES_ALLOWED', true);
        localStorage.setItem('RESTFUL_API_DEV_ANALYTICS_COOKIES_ALLOWED', true);

        window.gtag('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });

        setIsOpen(false);
    }

    function closePopup() {
        setIsOpen(false);
    }

    const handleOpenManageCookiesModal = () => {
        closePopup();
        setOpenManageCookiesModal(true);
    };
  
    return ( 
      <div className="cookies-popup">
        {isOpen && (
          <div>
            <Modal isOpen={isOpen} onRequestClose={closePopup} overlayClassName="cookies-popup">
                <p>We use cookies to improve your experience on our website. Read more in <Link className="menu-link" to="/privacy-policy#text">Privacy Policy</Link>.</p>
                <br/>
                <div className="cookies-popup-buttons">
                  <button aria-label="Manage cookies" className="manage-cookies-button" onClick={handleOpenManageCookiesModal}>Manage</button>
                  {props.widthSize < MOBILE_SCREEN_WIDTH ? (<span><br/><br/></span>) : (<span>&nbsp;&nbsp;&nbsp;</span>)}
                  <button aria-label="Accept Cookies" className="accept-cookies-button" onClick={acceptCookies}>Accept cookies</button>
                </div>
            </Modal>
          </div>
        )}
        {openManageCookiesModal && (<ManageCookies isOpen={true} isFromCookiesPopup={true} widthSize={props.widthSize}/>)}
      </div>
    );
}

export default CookiesPopup