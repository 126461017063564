import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

export default function Footer() {

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to top of page
  };

  return (
    <div>
      <footer className="footer">
       <div className='footer-links'>
        <ul className="menu">
          <li className="menu-item"><Link className="menu-link" to="/" onClick={handleLinkClick}>Home</Link></li>
          <li className="menu-item"><Link className="menu-link" to="/about">About</Link></li>
          <li className="menu-item"><Link className="menu-link" to="/contact#email">Contact</Link></li>
          <li className="menu-item"><Link className="menu-link" to="/privacy-policy#text">Privacy Policy</Link></li>
        </ul>
        <div className="digital-ocean">
          <a href="https://www.digitalocean.com/?refcode=4ad9734878c9&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge"><img src="https://web-platforms.sfo2.digitaloceanspaces.com/WWW/Badge%203.svg" alt="DigitalOcean Referral Badge" /></a>
        </div>
       </div>
       <div/>
        <div className="footer-copyright">
          &copy; {new Date().getFullYear()} Copyright:{' '} restful-api.dev
        </div>
      </footer>
    </div>
  );
}