import React from 'react';

function Introduction() {

  return (
    <div>
      <h2>REST API</h2>
      <hr/>
      <div className="introduction-text">
        <p>
          Welcome to our real REST API, where your data is securely stored in a real database, ensuring that your created data will be preserved and not lost. Our resource schema provides you with remarkable flexibility, allowing you to create custom objects with various attributes of different types. These attributes are stored as part of a "data" field, forming a customizable JSON object. This unique feature enables you to simulate a wide range of real-world application scenarios, from storing and retrieving prices, dates, and image URLs to simple text fields and beyond.
          <br/>
          <br/>
          To access the data, you don't always need to create it from scratch. Our REST API allows you to effortlessly fetch reserved mock data through a simple GET request. Additionally, you have the option to retrieve custom data that you've created yourself. You can either fetch a single object or request multiple objects in bulk by providing their respective object IDs as part of a single GET request. This way, you have full control and ease of access to the data you need for your application.   
        </p>
      </div>
    </div>
  )
}

export default Introduction