import React from 'react';

export const Endpoint = ({ isMobileVersion, color, requestMethodName, selected, requestMethodDescription, expand }) => {
  if (isMobileVersion) {
    return (
      <div className='request-mobile' style={{backgroundColor: color}}>
        <span className='request-method'>{requestMethodName}</span>
        <span className='endpoint-description-mobile'>{requestMethodDescription}</span>
        <span className='expand'>{ expand ? '+' : '-'}</span>
      </div>
    );
  } else {
    return (
      <div className='request' value={selected} style={{backgroundColor: color}}>
        <span className='request-method'>{requestMethodName}</span>
        <span className='endpoint-description'>{requestMethodDescription}</span>
      </div>
    );
  }

}

export default Endpoint