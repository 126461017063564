import React from 'react'
import addsBanner from '../../res/adds-banner.png';
import addsBannerMobille from '../../res/adds-banner-mobile.png';
import useScreenWidth from '../../hooks/useScreenWidth.js';
import { MOBILE_SCREEN_WIDTH } from '../../constants/constants.js';
import './styles.scss';

function AddsBannerFullWidth() {

    const widthSize = useScreenWidth();

    return (      
        <div className="adds-banner-full-width">
            <a href="https://www.apidog.com/?utm_source=blogger&utm_medium=apidev">
                {widthSize <= MOBILE_SCREEN_WIDTH ? (
                    <img src={addsBannerMobille} alt="API Dog" />
                ) : (
                    <img src={addsBanner} alt="API Dog" />
                )
                }
            </a>
        </div>
    );
}

export default AddsBannerFullWidth