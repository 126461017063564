import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

function TheoryMenuMobile() {

    return (   
        <div className='theory-menu-mobile'>
            <h2 style={{marginBottom: '4px'}}><b>See Also</b></h2>
           
            <h4 style={{marginBottom: '0px', color: 'rgb(48, 48, 48)'}}>LEARN REST</h4>
            <ul className="theory-menu-mobile-ul">
            <li className="theory-menu-item"><Link className="theory-menu-link" to="/rest-fundamentals">Rest Fundamentals</Link></li>
                <li className="theory-menu-item"><Link className="theory-menu-link" to="/">Send a Request to our REST API</Link></li>
                <li className="theory-menu-item"><Link className="theory-menu-link" to="/rest-api-history">REST API History</Link></li>
            </ul>
            
            <h4 style={{marginBottom: '0px', color: 'rgb(48, 48, 48)'}}>TUTORIALS</h4>
            <ul className="theory-menu-mobile-ul">
                <li className="theory-menu-item"><Link className="theory-menu-link" to="/send-a-get-request-java">Send a GET request in Java</Link></li>
                <li className="theory-menu-item"><Link className="theory-menu-link" to="/send-a-post-request-java">Send a POST request in Java</Link></li>
                <li className="theory-menu-item"><Link className="theory-menu-link" to="/send-a-put-request-java">Send a PUT request in Java</Link></li>
                <li className="theory-menu-item"><Link className="theory-menu-link" to="/send-a-patch-request-java">Send a PATCH request in Java</Link></li>
                <li className="theory-menu-item"><Link className="theory-menu-link" to="/send-a-delete-request-java">Send a DELETE request in Java</Link></li>
            </ul>
            <br/>
            <br/>
            <br/>
        </div>  
    );
}

export default TheoryMenuMobile