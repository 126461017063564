import React from 'react'
import MyHeader from './../../MyHeader.js';
import Menu from '../../Menu/Menu.js';
import Footer from './../../Footer.js';
import TheoryMenu from './../TheoryMenu.js';
import './../styles.scss';
import useScreenWidth from '../../../hooks/useScreenWidth.js';
import {SMALL_SCREEN_WIDTH} from '../../../constants/constants';
import MobileMenu from '../../Menu/MobileMenu.js';
import { Helmet } from 'react-helmet';
import TheoryMenuMobile from './../TheoryMenuMobile.js';
import CookiesPopup from '../../Cookies/CookiesPopup.js';
import AddsBannerFullWidth from '../../AddsBanner/AddsBannerFullWidth.js';

function DeleteRequestJava() {
    const widthSize = useScreenWidth();
    const PREPARING_A_REQUEST = 'HttpRequest request = HttpRequest.newBuilder()\n\t.DELETE()\n\t.uri(URI.create("https://api.restful-api.dev/objects/7"))\n\t.build();';
    const SENDING_A_REQUEST = 'HttpResponse<String> response = HttpClient.newHttpClient()\n\t.send(request, HttpResponse.BodyHandlers.ofString());\n\nSystem.out.println(response.statusCode());\nSystem.out.println(response.body());';
    const currentYear = new Date().getFullYear();
    const ALL_CODE = 'import java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpResponse;\n\npublic class Main {\n\n\
        public static void main(String[] args) throws Exception {\n\n\
            HttpRequest request = HttpRequest.newBuilder()\n\t\t.DELETE()\n\t\t.uri(URI.create("https://api.restful-api.dev/objects/7"))\n\t\t.build();\n\n\
            HttpResponse<String> response = HttpClient.newHttpClient()\n\t\t.send(request, HttpResponse.BodyHandlers.ofString());\n\n\
            System.out.println(response.statusCode());\n\
            System.out.println(response.body());\n\
        }\n\
}\
        ';

    return (   
        <div className='theory'>
            <Helmet>
                <link rel="canonical" href="https://restful-api.dev/send-a-delete-request-java" />
                <meta name='description' content='A Tutorial on how to send the HTTP DELETE request in Java to the REST API to to delete a resource'></meta>
                <title>Send a DELETE request in Java</title>
            </Helmet> 
            <div className="body">
                {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
                <MyHeader/>
                <div className='theory-page'>
                    {widthSize >= SMALL_SCREEN_WIDTH ? <TheoryMenu/> : null}
                  
                    <div className='theory-content-main'>
                        <div className="theory-content">
                            <br/>
                            <h2 style={{textAlign: 'left'}}>Send a DELETE request in Java</h2>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>What is a DELETE Request?</h3>
                            <p>A DELETE Request is one of the HTTP methods which is used when you want to delete a resource from a server. Typically, the resource which can be deleted would be identified by a unique ID or a combination of IDs.</p>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>1. How to send a DELETE request in Java?</h3>
                            <h3 style={{paddingTop: '10px'}}>1.1. Preparing a request</h3>
                            <pre>
                                <code>
                                    <p>
                                    {PREPARING_A_REQUEST}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>In order to send a DELETE request in Java, we can use the HttpRequest class to prepare our DELETE request. However, one thing to keep in mind is that HttpRequest class was introduced in Java 11 version. So, if let's say for some reason you are using Java 8 version in {currentYear}, this approach would not work for you. </p>
                            <p>In the code snippet above you can see that we are specifying that we want to send exactly a DELETE request since it is not a default method. Also we are passing the request URL where number 7 is a unique ID of a resource that we want to delete (P.S. You will need to create your own resource by <a href="/send-a-post-request-java">sending a POST request</a> since a resource with ID = 7 is a reserved ID and is used here just for an example purpose).</p>
                            
                            <br/>
                            <AddsBannerFullWidth/>
                            <br/>

                            <h3>1.2. Sending a request and printing a Response</h3>
                            <pre>
                                <code>
                                    <p>
                                    {SENDING_A_REQUEST}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                           <p>The HttpClient class (which also was introduced in Java 11 version) can be leveraged to send a request that we have prepared in the previous section. If you want, you can print the response code and a body of a DELETE request response to validate that your request was successful. </p>
                           <p>Depending on a REST API, valid response codes for a DELETE request would be either 200, 202, or 204. The response body is optional and might be empty or contain a message or something like this.</p>
                           <br/>
                           <h3>1.3. HTTP DELETE Response codes</h3>
                           <p><i style={{color: "green"}}>200 OK:</i> indicates that the resource has been successfully deleted from the server.</p>
                           <p><i style={{color: "green"}}>202 Accepted:</i> indicates that the server has received the request and has begun processing it, but the deletion may not have been completed yet.</p>
                           <p><i style={{color: "green"}}>204 No Content:</i> indicates that the resource has been successfully deleted, but there is no response body returned.</p>
                           <br/>
                           <h3>1.4. Putting everything together</h3>
                           <pre>
                                <code>
                                    <p>
                                    {ALL_CODE}
                                    </p>
                                </code>
                            </pre>
                            <br/><br/><br/>
                        </div>
                    </div>
                </div>
                {widthSize < SMALL_SCREEN_WIDTH ? <TheoryMenuMobile/> : null}
                <Footer/>
                <CookiesPopup widthSize={widthSize}/>
            </div>
        </div>  
    );
}

export default DeleteRequestJava