import './App.css';
import Home from './components/Home/Home.js';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy.js';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Theory from './components/Theory/RestFundamentals/Theory';
import RestHistory from './components/Theory/RestHistory/RestHistory';
import GetRequestJava from './components/Theory/Tutorials/GetRequestJava.js';
import PutRequestJava from './components/Theory/Tutorials/PutRequestJava.js';
import PostRequestJava from './components/Theory/Tutorials/PostRequestJava.js';
import PatchRequestJava from './components/Theory/Tutorials/PatchRequestJava.js';
import DeleteRequestJava from './components/Theory/Tutorials/DeleteRequestJava.js';
import PageNotFound from './components/NotFound/NotFoundPage';
import { useRoutes } from "react-router-dom";
import SupportUs from './components/SupportUs/SupportUs';

function App() {
  return useRoutes([
    {
      path: "/",
      element: <Home/>
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy/>
    },
    {
      path: "/about",
      element: <About/>
    },
    {
      path: "/contact",
      element: <Contact/>
    },
    {
      path: "/support-us",
      element: <SupportUs/>
    },
    {
      path: "/rest-fundamentals",
      element: <Theory/>
    },
    {
      path: "/rest-api-history",
      element: <RestHistory/>
    },
    {
      path: "/send-a-get-request-java",
      element: <GetRequestJava/>
    },
    {
      path: "/send-a-put-request-java",
      element: <PutRequestJava/>
    },
    {
      path: "/send-a-post-request-java",
      element: <PostRequestJava/>
    },
    {
      path: "/send-a-patch-request-java",
      element: <PatchRequestJava/>
    },
    {
      path: "/send-a-delete-request-java",
      element: <DeleteRequestJava/>
    },
    {
      path: "*",
      element: <PageNotFound/>
    }
  ]);
}

export default App;