import React, { useState } from "react";
import Endpoint from './Endpoint';
import RequestInfo from './RequestInfo';
import ResponseInfo from './ResponseInfo';
import {ENDPOINTS_INFO, SMALL_SCREEN_WIDTH} from '../../constants/constants';

function ApiCallsInfo (props) {

  const [activeId, setActiveId] = useState(1);
  const [requestUrl, setRequestUrl] = useState(ENDPOINTS_INFO[0].requestUrl);
  const [requestUrlHref, setRequestUrlHref] = useState(ENDPOINTS_INFO[0].requestUrlHref);
  const [responseJson, setResponseJson] = useState(ENDPOINTS_INFO[0].responseJson);
  const [requestJson, setRequestJson] = useState(ENDPOINTS_INFO[0].requestJson);
  const [activeIdsMobile, setActiveIdsMobile] = useState([1]);

  function handleAddOrRemoveId(id) {
    if (activeIdsMobile.includes(id)) {
      setActiveIdsMobile((activeIdsMobile) =>
        activeIdsMobile.filter((prevId) => prevId !== id)
      )
    } else {
      const newActiveIdsMobile = activeIdsMobile.concat(id);

      setActiveIdsMobile(newActiveIdsMobile);
    }
  }

  if (props.widthSize > SMALL_SCREEN_WIDTH) { 
    //logic for desktop
    return (
      <div className="api-calls-info-div">
        <h2>List of all endpoints</h2>
        <hr/>
        <div className="row">
          <div className="column">
            <ul>
                {ENDPOINTS_INFO.map((val) => (
                <li className="wrapper">
                    <div onClick={() => {setActiveId(val.id); setRequestUrl(val.requestUrl); setResponseJson(val.responseJson); setRequestJson(val.requestJson); setRequestUrlHref(val.requestUrlHref)}}>
                        <Endpoint isMobileVersion={false} requestMethodName={val.requestMethodName} requestMethodDescription={val.requestMethodDescription} selected = {activeId === val.id ? "selected" : "not selected"} color={activeId === val.id ? "rgba(3, 123, 252, .11)" : "white"}/>
                    </div>
                </li>
                ))}
            </ul>
          </div>
          <div className="column">
            <RequestInfo requestUrl = {requestUrl} requestJson = {requestJson} requestUrlHref = {requestUrlHref}/>
          </div>
          <div className="column">
            <ResponseInfo responseJson = {responseJson}/>
          </div>
        </div>
      </div>
    )
  } else {
    //logic for mobile
    return (
      <div className="ApiCallsInfoDivMobile">
        <h2>List of all endpoints</h2>
        <hr/>
        <div className="ApiCallsInfoDivMobileRow">
          <div className="ApiCallsInfoDivMobileColumn">
            <ul>
                {ENDPOINTS_INFO.map((val) => (
                <li className="wrapper">
                    <div>
                      <div onClick={() => {handleAddOrRemoveId(val.id); setRequestUrl(val.requestUrl); setResponseJson(val.responseJson); setRequestJson(val.requestJson); setRequestUrlHref(val.requestUrlHref)}}>
                        <Endpoint isMobileVersion={true} requestMethodName={val.requestMethodName} requestMethodDescription={val.requestMethodDescription} expand={!activeIdsMobile.includes(val.id)} color={activeIdsMobile.includes(val.id) ? "rgba(3, 123, 252, .1)" : "white"}/>
                        <br></br>
                      </div>
                      {
                        activeIdsMobile.includes(val.id) ?
                        (<div>
                          <RequestInfo requestUrl = {ENDPOINTS_INFO[val.id - 1].requestUrl} requestJson = {ENDPOINTS_INFO[val.id - 1].requestJson} requestUrlHref = {ENDPOINTS_INFO[val.id - 1].requestUrlHref}/>
                        </div>) : null
                      }
                      {
                        activeIdsMobile.includes(val.id) ?
                        (<div>
                          <ResponseInfo responseJson = {ENDPOINTS_INFO[val.id - 1].responseJson}/><br/><br/><br/>
                        </div>) : null
                      }
                    </div>
                </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default ApiCallsInfo