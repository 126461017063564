function SupportUsButton () {

    return (
        <div className="donate-link">
            <stripe-buy-button
                buy-button-id="buy_btn_1NzL1JFZbn4NgoOupUFG5Cfi"
                publishable-key="pk_live_51NzK5hFZbn4NgoOuwZrOcYngUrIra6j0i1ZnJf9G1auZLzoE5LDdwJRvWPzTGu2kvlCkZDrMmzePMSYrHil7geh100OVd4lJYM"
            >
            </stripe-buy-button>
        </div>
    );
}

export default SupportUsButton