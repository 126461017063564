import React from 'react'
import MyHeader from './../../MyHeader.js';
import Menu from '../../Menu/Menu.js';
import Footer from './../../Footer.js';
import TheoryMenu from './../TheoryMenu.js';
import './../styles.scss';
import useScreenWidth from '../../../hooks/useScreenWidth.js';
import {SMALL_SCREEN_WIDTH} from '../../../constants/constants';
import MobileMenu from '../../Menu/MobileMenu.js';
import { Helmet } from 'react-helmet';
import CookiesPopup from '../../Cookies/CookiesPopup.js';
import TheoryMenuMobile from './../TheoryMenuMobile.js';
import AddsBannerFullWidth from '../../AddsBanner/AddsBannerFullWidth.js';

function PatchRequestJava() {
    const widthSize = useScreenWidth();

    const EXISTING_RESOURCE_EXAMPLE = "{\n\
    \"id\": \"10\",\n\
    \"name\": \"BMW X5\",\n\
    \"data\": {\n\
        \"price\": 50000,\n\
        \"color\": \"Blue\"\n\
    }\n\
}";

    const PREPARING_A_REQUEST = "HttpRequest request = HttpRequest.newBuilder()\n\
    .uri(URI.create(\"https://api.restful-api.dev/objects/10\"))\n\
    .header(\"Content-Type\", \"application/json\")\n\
    .method(\"PATCH\", HttpRequest.BodyPublishers.ofString(\"{\\\"name\\\": \\\"BMW X5 M Sport\\\"}\"))\n\
    .build();"

    const SENDING_A_REQUEST = 'HttpResponse<String> response = HttpClient.newHttpClient()\n\t.send(request, HttpResponse.BodyHandlers.ofString());\n\nSystem.out.println(response.statusCode());\nSystem.out.println(response.body());';

    const POJO = "import com.fasterxml.jackson.annotation.JsonInclude;\n\
import java.util.HashMap;\n\n\
@JsonInclude(JsonInclude.Include.NON_NULL)\n\
public class Car {\n\n\
    private String name;\n\
    private HashMap<String, Object> data;\n\n\
    public String getName() {\n\
        return name;\n\
    }\n\n\
    public void setName(String name) {\n\
        this.name = name;\n\
    }\n\n\
    public HashMap<String, Object> getData() {\n\
        return data;\n\
    }\n\n\
    public void setData(HashMap<String, Object> data) {\n\
        this.data = data;\n\
    }\n\
}";

    const DEPENDENCY = "<dependency>\n\t<groupId>com.fasterxml.jackson.core</groupId>\n\t<artifactId>jackson-databind</artifactId>\n\t<version>2.13.1</version>\n</dependency>";

    const OBJECT_MAPPER = "Car car = new Car();\n\
car.setName(\"BMW X5 M Sport\");\n\n\
ObjectMapper objectMapper = new ObjectMapper();\n\
String requestBody = objectMapper.writeValueAsString(car);";

const OBJECT_MAPPER_REQUEST_BODY = "{\"name\":\"BMW X5 M Sport\"}";

    const ALL_CODE = "import com.fasterxml.jackson.databind.ObjectMapper;\n\n\
import java.net.URI;\n\
import java.net.http.HttpClient;\n\
import java.net.http.HttpRequest;\n\
import java.net.http.HttpResponse;\n\n\
public class Main {\n\n\
    public static void main(String[] args) throws Exception {\n\n\
        Car car = new Car();\n\
        car.setName(\"BMW X5 M Sport\");\n\n\
        ObjectMapper objectMapper = new ObjectMapper();\n\
        String requestBody = objectMapper.writeValueAsString(car);\n\n\
        HttpRequest request = HttpRequest.newBuilder()\n\
                .uri(URI.create(\"https://api.restful-api.dev/objects/10\"))\n\
                .header(\"Content-Type\", \"application/json\")\n\
                .method(\"PATCH\", HttpRequest.BodyPublishers.ofString(requestBody))\n\
                .build();\n\n\
        HttpResponse<String> response = HttpClient.newHttpClient()\n\
                .send(request, HttpResponse.BodyHandlers.ofString());\n\n\
        System.out.println(response.statusCode());\n\
        System.out.println(response.body());\n\
    }\n\
}\
";

    return (   
        <div className='theory'>
            <Helmet>
                <link rel="canonical" href="https://restful-api.dev/send-a-patch-request-java" />
                <meta name='description' content='A Tutorial on how to send the HTTP PATCH request in Java to the REST API to perform a partial update of a resource'></meta>
                <title>Send a PATCH request in Java</title>
            </Helmet> 
            <div className="body">
                {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
                <MyHeader/>
                <div className='theory-page'>
                    {widthSize >= SMALL_SCREEN_WIDTH ? <TheoryMenu/> : null}
                  
                    <div className='theory-content-main'>
                        <div className="theory-content">
                        <br/>
                            <h2 style={{textAlign: 'left'}}>Send a PATCH request in Java</h2>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>What is a PATCH Request?</h3>
                            <p>A PATCH request is one of the HTTP methods which is used when we want to perform a partial or a full update of an existing resource. The most similar to a PATCH request is a PUT request but a PUT request doesn't allow a partial update which is the main difference between them.</p>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>1. How to send a PATCH request in Java?</h3>
                            <h3 style={{paddingTop: '10px'}}>1.1. Preparing a request</h3>
                            <p>Let's think about an example and let's say that we have our own website on which people can sell some used cars. Imagine that on that website, we use different HTTP methods which help us to fetch and store data and we also support a PATCH request. Now imagine that we have decided to sell our own car on our own website and we already have published a listing as in the example below but after some time we decided to edit a name attribute to include more details about the car which we want to sell. What if we want to update only the name attribute but don't want to touch other attributes, how can we do it via a PATCH request?</p>
                            <pre>
                                <code>
                                    <p>
                                    {EXISTING_RESOURCE_EXAMPLE}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>We can prepare our PATCH request using HttpRequest class that was introduced in Java 11. Using HttpRequest class we should provide our request URL which would contain the unique ID of a resource that we want to update. We should set up a Content-Type header to specify the type of our request body which we are sending and we should do it correctly because we need to make sure that the type is the same as REST API expects it to be or otherwise, our request would fail. We also need to specify what kind of HTTP request we are sending so that the REST API would handle the PATCH request as a PATCH and not as something else. And as a last thing, we should provide our request body which should contain the attributes that we want to update, simply as that.</p>
                            <pre>
                                <code>
                                    <p>
                                    {PREPARING_A_REQUEST}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>Just one thing to keep in mind is that the ID used in the example above that is equal to 10 is a reserved ID and is used here only for an example purpose. To properly update a resource using a PATCH request through our REST API, you would need to create your own resource using a <a href="/send-a-put-request-java">POST request</a>.</p>
                            
                            <br/>
                            <AddsBannerFullWidth/>
                            <br/>

                            <h3>1.2. Sending a request</h3>
                            <pre>
                                <code>
                                    <p>
                                    {SENDING_A_REQUEST}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>Once a request is ready, we can use HttpClient class to send our PATCH request. After that, we can print a response code and a response body just to validate that the request was successful.</p>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>2. How to send a Java Object as request body?</h3>
                            <h3 style={{paddingTop: '10px'}}>2.1. Creating an Object</h3>
                            <p>In the previous section, we prepared our request body by creating a String which represents a JSON object since this REST API expects a request body to be of type JSON. Yes, it worked but how can we just send some proper Java object instead? Let's start with creating this Object first.</p>
                            <pre>
                                <code>
                                    <p>
                                    {POJO}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>You can notice a JsonInclude annotation with a non-null parameter. Why do we need it and what does it do? </p>
                            <p>Since a PATCH request allows a partial update and since the default value of the Java Object's variables is null, we need to make sure that our request body would not contain null values. Otherwise, what would happen is that the PATCH request might override the resource attributes which we didn't want to update and it would set the values for them to null. Imagine that you create a new Car object, you set the new "name" value for it but don't do anything with "data" variable. If you send this Object to a REST API via a PATCH request, since the "data" value is null because null is a default value, the REST API would consider it as an update and would set it to null on its side as well. Obviously, this is not what we want to do because we would lose an existing data in this case. </p>
                            <p>The JsonInclude annotation will help us to avoid this problem when we would use the ObjectMapper class (Section 2.3) to convert our Java Object to a String which represents a JSON. JsonInclude annotation would make sure that ObjectMapper ignores all of the null values and it would not add them to the request body.</p>
                            <br/>
                            <h3>2.2. Jackson library</h3>
                            <p>Both JsonInclude annotation and ObjectMapper class which we are going to use in the next session come from an external library which is called Jackson. This library needs to be added to your project dependencies. So, if you are using Maven, copy-paste the code below to your pom.xml or if you are using Gradle, copy-paste it to your "build.gradle" file.</p>
                            <pre>
                                <code>
                                    <p>
                                    {DEPENDENCY}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <h3>2.3. Object Mapper</h3>
                            <p>Let's initialize now our custom Java Object and let's pass an updated "name" variable value to it. After that, let's create an instance of ObjectMapper class and let's use the writeValueAsString method from this class. This method would convert our Java Object to a String which represents a JSON and since we used a JsonInclude annotation and specified that we do not want to include any null values, the new JSON String created would contain only the variables which have been set, in our case it is a "name" variable only. </p>
                            <pre>
                                <code>
                                    <p>
                                    {OBJECT_MAPPER}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>Now our program is much more powerful and it can convert the Java Object to a request body of a type JSON. If we print our Request Body, we would get something like this:</p>
                            <pre>
                                <code>
                                    <p>
                                    {OBJECT_MAPPER_REQUEST_BODY}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>3. Putting everything together</h3>
                            <pre>
                                <code>
                                    <p>
                                        {ALL_CODE}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>

                    
                </div>
                {widthSize < SMALL_SCREEN_WIDTH ? <TheoryMenuMobile/> : null}
                <Footer/>
                <CookiesPopup widthSize={widthSize}/>
            </div>
        </div>  
    );
}

export default PatchRequestJava