import React, { useEffect } from 'react'
import MyHeader from '../../MyHeader.js';
import Menu from '../../Menu/Menu.js';
import Footer from '../../Footer.js';
import RestFundamentals from './RestFundamentals.js';
import TheoryMenu from '../TheoryMenu.js';
import TheoryMenuMobile from '../TheoryMenuMobile.js';
import './../../../App.css';
import './../styles.scss';
import {useLocation} from "react-router-dom";
import useScreenWidth from '../../../hooks/useScreenWidth.js';
import {SMALL_SCREEN_WIDTH} from '../../../constants/constants.js';
import MobileMenu from '../../Menu/MobileMenu.js';
import { Helmet } from 'react-helmet';
import CookiesPopup from '../../Cookies/CookiesPopup.js';


function Theory() {
    const location = useLocation();
    const widthSize = useScreenWidth();

    useEffect(() => {
        if (location.hash && location.hash !== "#rest") {
            const id = location.hash.replace("#", "");
            document.getElementById(id).style.backgroundColor = 'rgba(3, 123, 252, .1)';
 
            const timer = window.setInterval(() => {
                document.getElementById(id).style.backgroundColor = 'white';
            }, 4000);
    
            return () => { 
                window.clearInterval(timer);
            }
        }
      }, []);

    return (   
        <div className='theory'>
            <Helmet>
                <link rel="canonical" href="https://restful-api.dev/rest-fundamentals" />
                <meta name='description' content='A REST (Representational State Transfer) API is an architectural style for an API that uses HTTP (Hypertext Transfer Protocol) request methods to access and manipulate data over the Internet. The most popular HTTP request methods are GET, POST, PUT, DELETE, PATCH, HEAD, TRACE, CONNECT and OPTIONS.'></meta>
                <title>REST API fundamentals</title>
            </Helmet> 
            <div className="body">
                {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
                <MyHeader/>
                <div className='theory-page'>
                    {widthSize >= SMALL_SCREEN_WIDTH ? <TheoryMenu/> : null}
                    <RestFundamentals/>
                    
                </div>
                {widthSize < SMALL_SCREEN_WIDTH ? <TheoryMenuMobile/> : null}
                <Footer/>
                <CookiesPopup widthSize={widthSize}/>
            </div>
        </div>  
    );
}

export default Theory