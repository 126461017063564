import React from 'react'
import MyHeader from './../../MyHeader.js';
import Menu from '../../Menu/Menu.js';
import Footer from './../../Footer.js';
import TheoryMenu from './../TheoryMenu.js';
import './../styles.scss';
import useScreenWidth from '../../../hooks/useScreenWidth.js';
import {SMALL_SCREEN_WIDTH} from '../../../constants/constants';
import MobileMenu from '../../Menu/MobileMenu.js';
import { Helmet } from 'react-helmet';
import CookiesPopup from '../../Cookies/CookiesPopup.js';
import TheoryMenuMobile from './../TheoryMenuMobile.js';
import AddsBannerFullWidth from '../../AddsBanner/AddsBannerFullWidth.js';

function PostRequestJava() {
    const widthSize = useScreenWidth();
    const PREPARING_A_REQUEST = "String requestBody = \"{\\\"name\\\": \\\"Example\\\", \\\"data\\\": {\\\"Attribute 1\\\": 123, \\\"Attribute 2\\\": \\\"POST Request in Java\\\"}}\";\n\n\
HttpRequest request = HttpRequest.newBuilder()\n\t\
.POST(HttpRequest.BodyPublishers.ofString(requestBody))\n\t\
.uri(URI.create(\"https://api.restful-api.dev/objects\"))\n\t\
.header(\"Content-Type\", \"application/json\")\n\t\
.build();\
";
    const SENDING_A_REQUEST = 'HttpResponse<String> response = HttpClient.newHttpClient()\n\t.send(request, HttpResponse.BodyHandlers.ofString());\n\nSystem.out.println(response.statusCode());\nSystem.out.println(response.body());';
    const RESPONSE_BODY = "{\n\
        \"id\": \"Some_Unique_ID\",\n\
        \"name\": \"Example\",\n\
        \"createdAt\": \"2023-04-15T19:14:00.129+00:00\",\n\
        \"data\": {\n\
            \"Attribute 1\": 123,\n\
            \"Attribute 2\": \"POST Request in Java\"\n\
        }\n\
}\
    ";
    const POJO = "import java.util.HashMap;\n\n\
public class MyObject {\n\n\
        private String name;\n\
        private HashMap<String, Object> data;\n\n\
        public MyObject(String name, HashMap<String, Object> data) {\n\
            this.name = name;\n\
            this.data = data;\n\
        }\n\n\
        public String getName() {\n\
            return name;\n\
        }\n\n\
        public void setName(String name) {\n\
            this.name = name;\n\
        }\n\n\
        public HashMap<String, Object> getData() {\n\
            return data;\n\
        }\n\n\
        public void setData(HashMap<String, Object> data) {\n\
            this.data = data;\n\
        }\n\
}";
    const DEPENDENCY = "<dependency>\n\t<groupId>com.fasterxml.jackson.core</groupId>\n\t<artifactId>jackson-databind</artifactId>\n\t<version>2.13.1</version>\n</dependency>";
    const OBJECT_MAPPER = "HashMap<String, Object> dataAttributes = new HashMap<>();\n\
dataAttributes.put(\"Attribute 1\", 123); \n\
dataAttributes.put(\"Attribute 2\", \"POST Request in Java\");\n\n\
MyObject myObject = new MyObject(\"Example\", dataAttributes);\n\n\
ObjectMapper objectMapper = new ObjectMapper();\n\
String requestBody = objectMapper.writeValueAsString(myObject);\n\
";
    const ALL_CODE = "import com.fasterxml.jackson.databind.ObjectMapper;\n\n\
import java.net.URI;\n\
import java.net.http.HttpClient;\n\
import java.net.http.HttpRequest;\n\
import java.net.http.HttpResponse;\n\
import java.util.HashMap;\n\n\
public class Main {\n\n\
    public static void main(String[] args) throws Exception {\n\n\
        HashMap<String, Object> dataAttributes = new HashMap<>();\n\
        dataAttributes.put(\"Attribute 1\", 123);\n\
        dataAttributes.put(\"Attribute 2\", \"POST Request in Java\");\n\n\
        MyObject myObject = new MyObject(\"Example\", dataAttributes);\n\n\
        ObjectMapper objectMapper = new ObjectMapper();\n\
        String requestBody = objectMapper.writeValueAsString(myObject);\n\n\
        HttpRequest request = HttpRequest.newBuilder()\n\
                .POST(HttpRequest.BodyPublishers.ofString(requestBody))\n\
                .uri(URI.create(\"https://api.restful-api.dev/objects\"))\n\
                .header(\"Content-Type\", \"application/json\")\n\
                .build();\n\n\
        HttpResponse<String> response = HttpClient.newHttpClient()\n\
                .send(request, HttpResponse.BodyHandlers.ofString());\n\n\
        System.out.println(response.statusCode());\n\
        System.out.println(response.body());\n\
    }\n\
}\
";


    return (   
        <div className='theory'>
            <Helmet>
                <link rel="canonical" href="https://restful-api.dev/send-a-post-request-java" />
                <meta name='description' content='A Tutorial on how to send the HTTP POST request in Java to create a new resource'/>
                <title>Send a POST request in Java</title>
            </Helmet> 
            <div className="body">
                {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
                <MyHeader/>
                <div className='theory-page'>
                    {widthSize >= SMALL_SCREEN_WIDTH ? <TheoryMenu/> : null}
                  
                    <div className='theory-content-main'>
                        <div className="theory-content">
                            <br/>
                            <h2 style={{textAlign: 'left'}}>Send a POST request in Java</h2>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>What is a POST Request?</h3>
                            <p>A POST Request is one of the most popular HTTP methods which is used when you want to create a new resource that would store some data. The data which is sent as part of a POST request is usually called a request body. Depending on a REST API and on an endpoint to which you are sending a POST request, the request body might be of a different type, for example, it can be a JSON, plain text, XML, etc.</p>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>1. How to send a POST request in Java?</h3>
                            <h3 style={{paddingTop: '10px'}}>1.1. Preparing a request</h3>
                            <pre>
                                <code>
                                    <p>
                                    {PREPARING_A_REQUEST}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>Our REST API expects a request body to be of type JSON, that's why, in the example above we created a String which represents a JSON Object.  The attributes which we have created as part of a request body ("name" and "data") are not random. This particular POST endpoint recognizes the "name" attribute of type String and a "data" attribute is generic. In our case, we are passing a "data" attribute as another JSON which can have different attributes of different types but you can customize the "data" attribute type as you want, for example, it can be just a single String, Integer or float, etc. </p>
                            <p>To prepare our POST request, we are using the HttpRequest class from Java 11 version. We specify that we want to send exactly a POST request and also we provide the request body which we want to send as part of the POST request. After that, we provide the request URL of a POST request and also set up the Content-Type request header. The Content-Type header should be specified correctly because if it would not be of the same type as REST API expects it to be, the request would fail!</p>
                         
                            <br/>
                            <AddsBannerFullWidth/>
                            <br/>

                            <h3>1.2. Sending a request</h3>
                            <pre>
                                <code>
                                    <p>
                                    {SENDING_A_REQUEST}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>The HttpClient class which also comes from Java 11 version allows us to send a request. We can print our response code and response body which we would get back from a REST API.</p>
                            <p>In case if the POST request was successful, the response code would be equal to 200 and the response body from this request would look like this:</p>
                            <pre>
                                <code>
                                    <p>
                                    {RESPONSE_BODY}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>2. How to send a request body as a Java Object?</h3>
                            <h3 style={{paddingTop: '10px'}}>2.1. Creating an Object</h3>
                            <p>In section 1.1 we have constructed the request body ourselves by manually creating a String which represents a JSON. Yes, this worked and our request was successful but how can we do it programmatically and how can we just simply take a normal Java Object and convert it to String which would represent a JSON without manually constructing anything? Let's start with the simplest things first, let's create a plain Java Object.</p>
                            <pre>
                                <code>
                                    <p>
                                    {POJO}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <h3>2.2. Jackson library</h3>
                            <p>Since our REST API expects a request body to be a JSON, we will need to convert our Object to a JSON as well. To do it quickly and simply we are going to use one external library called Jackson. It is a very popular Java library that can help you to do all kinds of JSON processing in Java. However, since it is an external library, you need to add the Jackson dependency to your project's build file. For example, if you are using Maven, you can add the dependency below to your project's pom.xml file or if you are using Gradle, you can add it to your "build.gradle" file.</p>
                            <pre>
                                <code>
                                    <p>
                                    {DEPENDENCY}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <h3>2.3. Object Mapper</h3>
                            <p>The reason why we have imported a Jackson library is that we are going to leverage an ObjectMapper class that comes from a Jackson library. If we populate our custom Object as in the code snippet below and if we create an instance of an ObjectMapper class and call a "writeValueAsString" method from this class, it would convert our custom Java Object to a String that represents a JSON, the same as we have constructed ourselves manually in section 1.1.</p>
                            <pre>
                                <code>
                                    <p>
                                    {OBJECT_MAPPER}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>Congratulations, now our code is more powerful and we can send POST requests using normal Java Objects!</p>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>3. Putting everything together</h3>
                            <pre>
                                <code>
                                    <p>
                                        {ALL_CODE}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
                {widthSize < SMALL_SCREEN_WIDTH ? <TheoryMenuMobile/> : null}
                <Footer/>
                <CookiesPopup widthSize={widthSize}/>
            </div>
        </div>  
    );
}

export default PostRequestJava