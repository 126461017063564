import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './styles.scss';
import Toggle from './Toggle.js';


function ManageCookies(props) {
    const [isOpen, setIsOpen] = useState(false);

    const [preferences, setPreferences] = useState({
      essential: true,
      analytics: localStorage.getItem('RESTFUL_API_DEV_ANALYTICS_COOKIES_ALLOWED') === null ? true : localStorage.getItem('RESTFUL_API_DEV_ANALYTICS_COOKIES_ALLOWED') === 'true',
      marketing: localStorage.getItem('RESTFUL_API_DEV_MARKETING_COOKIES_ALLOWED') === null ? true :  localStorage.getItem('RESTFUL_API_DEV_MARKETING_COOKIES_ALLOWED') === 'true',
    });

    const togglePreference = preference => {
      setPreferences({
        ...preferences,
        [preference]: !preferences[preference],
      });
    };

    useEffect(() => {
        setIsOpen(true);
    }, []);

    function closePopup() {
        setIsOpen(false);
        if (!props.isFromCookiesPopup) {
          props.closeManageCookiesModal();
        }
    }

    function saveCookiesPreferences() {
      localStorage.setItem('RESTFUL_API_DEV_MARKETING_COOKIES_ALLOWED', preferences.marketing);
      localStorage.setItem('RESTFUL_API_DEV_ANALYTICS_COOKIES_ALLOWED', preferences.analytics);

      window.gtag('consent', 'update', {
        'ad_storage': preferences.marketing ? 'granted' : 'denied',
        'analytics_storage': preferences.analytics ? 'granted' : 'denied'
      });

      closePopup();
    }
  
    return (
      <div className="manage-cookies-modal">
        <Modal isOpen={isOpen} onRequestClose={closePopup} overlayClassName="manage-cookies-modal">
          <button aria-label="Close" className="close" onClick={closePopup}>
            <span className="close-icon">&times;</span>
          </button>
          <div className="manage-cookies">
            <h3>Cookie Manager</h3>
            <br/>
            <div className="cookie-selection">
              <div className="cookies-description">
                <span>Essential cookies:</span>
                <h5>These cookies are essential for the operation and security of our site.</h5>
              </div>
              <div className="cookies-toggle">
                <label>
                  <Toggle
                    checked={preferences.essential}
                    onChange={() => togglePreference('essential')}
                    essential={true}
                    widthSize = {props.widthSize}
                  />
                </label>
              </div>
            </div>
            <br/>
            <div className="cookie-selection">
              <div className="cookies-description">
                <span>Analytics cookies:</span>
                <h5>Analytical cookies help us to determine usage of site, for example count number of visitors and to see how visitors move around our site when they are using it.</h5>
              </div>
              <div className="cookies-toggle">
                <label>
                  <Toggle
                    checked={preferences.analytics}
                    onChange={() => togglePreference('analytics')}
                    essential={false}
                    widthSize = {props.widthSize}
                  />
                </label>
              </div>
            </div>
            <br/>
            <div className="cookie-selection">
              <div className="cookies-description">
                <span>Marketing cookies:</span>
                <h5>Marketing cookies help us to determine ad targeting and ad measurement.</h5>
              </div>
              <div className="cookies-toggle">
                <label>
                  <Toggle
                    checked={preferences.marketing}
                    onChange={() => togglePreference('marketing')}
                    essential={false}
                    widthSize = {props.widthSize}
                  />
                </label>
              </div>
            </div>
          </div>
          <br/><br/><br/>
          <div className="save-cookies-button">
            <button aria-label="Save" onClick={saveCookiesPreferences} >Save</button>
          </div>
        </Modal>
      </div>
    );
}

export default ManageCookies