import React from 'react'
import useScreenWidth from '../../../hooks/useScreenWidth.js';
import MyHeader from './../../MyHeader.js';
import Menu from '../../Menu/Menu.js';
import './../styles.scss';
import {SMALL_SCREEN_WIDTH} from '../../../constants/constants';
import MobileMenu from '../../Menu/MobileMenu.js';
import { Helmet } from 'react-helmet';
import TheoryMenu from './../TheoryMenu.js';
import TheoryMenuMobile from './../TheoryMenuMobile.js';
import CookiesPopup from '../../Cookies/CookiesPopup.js';
import Footer from './../../Footer.js';
import AddsBannerFullWidth from '../../AddsBanner/AddsBannerFullWidth.js';

function RestHistory() {

    const widthSize = useScreenWidth();

    return (   
        <div className='theory'>
            <Helmet>
                <link rel="canonical" href="https://restful-api.dev/rest-api-history" />
                <meta name='description' content='Discover the History of REST API - Unravel the origins of Representational State Transfer (REST) as an ingenious architectural style proposed by Roy Fielding in the early 2000s. Explore its evolution and how RESTful APIs have revolutionized modern software development. From seamless interaction with web servers to empowering diverse applications, delve into the significance and timeless legacy of RESTful APIs in the digital landscape."'></meta>
                <title>History of a REST API</title>
            </Helmet> 

            <div className="body">
                {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
                <MyHeader/>
                <div className='theory-page'>
                    {widthSize >= SMALL_SCREEN_WIDTH ? <TheoryMenu/> : null}
                  
                    <div className='theory-content-main'>
                        <div className="theory-content">
                            <br/>
                            <h2 style={{textAlign: 'left'}}>History of a REST API</h2>
                            <p>The history of REST API dates back to the early 2000s when it was introduced by Roy Fielding in his doctoral dissertation titled "Architectural Styles and the Design of Network-based Software Architectures". In this dissertation, Roy Fielding presented the Representational State Transfer (REST) as an architectural style for designing networked applications.</p>
                            <p>REST is based on the principles of the World Wide Web and was proposed as an alternative to other web service protocols like SOAP (Simple Object Access Protocol) and XML-RPC (Remote Procedure Call). REST aimed to provide a simpler, lightweight, and more scalable approach to web service architecture.</p>
                            <p>The key concepts behind REST are based on the HTTP protocol, which is the foundation of the World Wide Web. RESTful APIs use standard HTTP methods such as GET, POST, PUT, DELETE, etc., to interact with resources on a web server.</p>
                            <AddsBannerFullWidth/>
                            <p>Over time, RESTful APIs gained popularity due to their simplicity, scalability, and ease of use. They became widely adopted as the preferred way to expose and consume data and services on the web.</p>
                            <p>With the growth of the internet and the increasing need for web and mobile applications to communicate with servers, RESTful APIs became a fundamental component of modern software development. Many web services, social media platforms, e-commerce sites, and mobile applications utilize REST APIs to enable communication between client and server.</p>
                            <p>As the demand for interconnected services and microservices architecture increased, RESTful APIs continued to evolve. Developers introduced best practices, standards, and conventions to make REST APIs more consistent and easy to use.</p>
                            <p>Today, REST APIs are a ubiquitous part of web development, powering numerous applications and services across various industries. They remain a fundamental aspect of modern software architecture and are likely to continue playing a crucial role in the future of web and mobile application development.</p>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
                {widthSize < SMALL_SCREEN_WIDTH ? <TheoryMenuMobile/> : null}
                <Footer/>
                <CookiesPopup widthSize={widthSize}/>
            </div>
        </div>
    );
}

export default RestHistory