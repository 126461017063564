import React from 'react'
import MyHeader from './../MyHeader.js';
import Menu from '../Menu/Menu.js';
import Footer from './../Footer.js';
import './../../App.css';
import './styles.scss';
import useScreenWidth from '../../hooks/useScreenWidth.js';
import {SMALL_SCREEN_WIDTH} from '../../constants/constants';
import MobileMenu from '../Menu/MobileMenu.js';
import CookiesPopup from '../Cookies/CookiesPopup.js';

function Contact() {

    const widthSize = useScreenWidth();

    return (      
        <div className="body">
            {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
            <MyHeader/>
            <h2>Contact</h2>
            <hr/>
            <div className="contact-text">
                <p id="email">Have a question, suggestion, or spotted a bug? We'd love to hear from you! Feel free to reach out to us by sending an email to <b>contact@restful-api.dev</b>, and we'll get back to you as soon as possible. Thank you!</p>
                <br/><br/><br/>
            </div>
            <Footer/>
            <CookiesPopup widthSize={widthSize}/>
        </div>
    );
}

export default Contact