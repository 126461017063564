import React from 'react';
import Menu from '../Menu/Menu.js';
import MobileMenu from '../Menu/MobileMenu.js';
import MyHeader from '../MyHeader.js';
import Introduction from './Introduction.js';
import ApiCallsInfo from './ApiCallsInfo.js';
import GettingStarted from './GettingStarted.js';
import CookiesPopup from '../Cookies/CookiesPopup.js';
import FAQ from './FAQ.js';
import Footer from '../Footer.js';
import useScreenWidth from '../../hooks/useScreenWidth.js';
import './../../App.css';
import {SMALL_SCREEN_WIDTH} from '../../constants/constants';
import AddsBanner from '../AddsBanner/AddsBanner.js';

function Home() {

    const widthSize = useScreenWidth();

    return (      
        <div className="home">
            {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
            <MyHeader/>
            <Introduction/>
            <AddsBanner/>
            <ApiCallsInfo widthSize={widthSize}/>
            <GettingStarted/>
            <FAQ/>
            <Footer/>
            <CookiesPopup widthSize={widthSize}/>
        </div>
    );
}

export default Home