import React, { useState }  from 'react'
import './styles.scss';
import './../../App.css';
import ManageCookies from '../Cookies/ManageCookies.js';

function PrivacyPolicySection(props) {
  const [isManageCookiesModalOpen, setIsManageCookiesModalOpen] = useState(false);

  function openManageCookiesModal() {
    setIsManageCookiesModalOpen(true);
  }

  function closeManageCookiesModal() {
    setIsManageCookiesModalOpen(false);
  }

  return (      
      <div>
      <h2>Privacy Policy</h2>
      <hr/>
    
      <div className="privacy-policy-text" >
      <h2 id="text" className="privacy-policy">Privacy Policy</h2>
        <p>Last updated: August 1, 2023</p>
        <p>This Privacy Policy outlines our practices regarding the gathering, utilization, and disclosure of your information when using the restful-api.dev service, and informs you about your privacy rights and legal protections.</p>
        <p>We utilize your personal data to deliver and enhance our service. When using this service, you consent to the collection and usage of information as outlined in this Privacy Policy.</p>
        
        <br/>
        <h2>Collecting and Using Your Personal Data</h2>
        <h3 style={{paddingTop: "5px"}}>Personal Data</h3>
        <p>During your usage of our Service, we may request that you provide certain personally identifiable information that enables us to identify you. This information may include, but is not limited to, Usage Data.</p>
        
        <h3 style={{paddingTop: "5px"}}>Usage Data</h3>
        <p>Usage Data is automatically collected when you access the restful-api.dev website.</p>
        <p>This data may encompass information such as your Device's Internet Protocol (IP) address, browser type, browser version, the specific pages you visit on our Service, the date and time of your visit, the duration spent on those pages, unique device identifiers, and other diagnostic data.</p>
        <p>If you access the restful-api.dev website via a mobile device, certain information may be collected automatically, including but not limited to the type of mobile device you use, your mobile device's unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile internet browser you use, unique device identifiers, and other diagnostic data.</p>
        
        <br/>
        <h2>Cookies</h2>
        <p>We employ cookies to store and retrieve identifiers and other data on computers, phones, and other devices.</p>
        <p>The cookies we utilize fall into the following categories:</p>
        <ul>
          <li><strong>Necessary cookies:</strong> These cookies are essential for the operation and security of our site.</li>
          <li><strong>Analytical cookies:</strong> These cookies assist us in analyzing site usage, such as counting the number of visitors and tracking how they navigate our site.</li>
          <li><strong>Marketing cookies:</strong> Marketing cookies aid us in determining ad targeting and measuring ad effectiveness.</li>
        </ul>

        <p>You can always change your preferences through our <a href="javascript:void(0)" onClick={openManageCookiesModal}>Cookie Manager.</a></p>
        {isManageCookiesModalOpen && <ManageCookies closeManageCookiesModal={closeManageCookiesModal} widthSize={props.widthSize}/> }
        <br/>
        <h2>Use of Your Personal Data</h2>
        <p>At our Company, we utilize your Personal Data for the following purposes:</p>
        <ul>
          <li>
            <p><strong>Service Provision and Maintenance:</strong> We employ your Personal Data to provide and maintain our Service, which includes monitoring the usage of the Service.</p>
          </li>
          <li>
            <p><strong>Request Management:</strong> We manage and attend to any requests you make to us.</p>
          </li>
          <li>
            <p><strong>Other Purposes:</strong> Your information may also be used for data analysis, identifying usage trends, evaluating the effectiveness of our promotional campaigns, and enhancing our Service, products, services, marketing, and your overall experience.</p>
          </li>
        </ul>
        <p>We may share Your personal information in the following circumstances:</p>
        <ul>
          <li><p><strong>Service Providers:</strong> For the purpose of monitoring and analyzing the use of our Service, we may share your personal information with Service Providers.</p></li>
          <li><p><strong>Affiliates:</strong> Your information may be shared with our affiliates, including our parent company, subsidiaries, joint venture partners, or other companies under common control with us. Rest assured, these affiliates will be obligated to uphold this Privacy Policy.</p></li>
          <li><p><strong>Business partners:</strong>  We may disclose your information to our business partners in order to provide you with specific products, services, or promotions.</p></li>
          <li><p><strong>With Your consent:</strong> For any other purposes not explicitly mentioned above, we will seek your consent before disclosing your personal information.</p></li>
        </ul>
        <br/>
        <h2>Retention of Your Personal Data</h2>
        <p>At our Company, we will keep your Personal Data only for the duration required to fulfill the purposes outlined in this Privacy Policy. Your Personal Data will be retained and utilized to the extent necessary to comply with legal obligations (e.g., adhering to applicable laws), settle disputes, and enforce our legal agreements and policies.</p>
        <p>Additionally, Usage Data will be retained for internal analysis purposes. Generally, Usage Data is stored for a shorter period. However, in cases where this data is utilized to enhance the security or functionality of Our Service, or if we are legally obliged to retain it for longer periods, we will do so.</p>
        <br/>
        <h2>Transfer of Your Personal Data</h2>
        <p>Your data, including Personal Data, undergoes processing at the Company's operating offices and other locations where the involved parties may be situated. Consequently, this information might be transferred to and stored on computers situated outside your state, province, country, or other governmental jurisdiction where data protection laws may differ from those in your jurisdiction.</p>
        <p>By providing Your information and agreeing to this Privacy Policy, You consent to such transfers.</p>
        <p>The Company will take all reasonably necessary measures to ensure that Your data is treated securely and in accordance with this Privacy Policy. No transfer of Your Personal Data will occur to any organization or country unless there are adequate controls in place to safeguard Your data and other personal information.</p>
        <br/>
        <h2>Delete Your Personal Data</h2>
        <p>You hold the right to delete your Personal Data collected by us, and we are ready to assist you in this process.</p>
        <p>Within our Service, you may find options that allow you to delete specific information about yourself.</p>
        <p>If needed, you can contact us directly to request access to, correct, or delete any personal information you have provided to us.</p>
        <p>It is important to acknowledge that certain information may need to be retained if we have a legal obligation or lawful basis to do so.</p>
        <br/>
        <h2>Disclosure of Your Personal Data</h2>
        <h3>Law enforcement</h3>
        <p>In specific situations, the Company may be obligated to disclose Your Personal Data if required by law or in response to valid requests from public authorities, such as a court or a government agency.</p>
        <h3>Other legal requirements</h3>
        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>Prevent or investigate potential wrongdoing associated with the Service</li>
        <li>Safeguard the personal safety of Service Users or the public</li>
        <li>Mitigate legal liability</li>
        </ul>
        <br/>
        <h2>Security of Your Personal Data</h2>
        <p>We prioritize the security of Your Personal Data; however, please be aware that no method of transmission over the Internet or electronic storage is entirely secure. While We make every effort to use commercially acceptable means to safeguard Your Personal Data, We cannot guarantee its absolute security.</p>
        <br/>
        <h2>Links to Other Websites</h2>
        <p>Our Service may include links to other websites that are not operated by Us. If You click on a third-party link, You will be redirected to the respective third-party site. We strongly advise You to review the Privacy Policy of each site You visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
        <br/>
        <h2>Changes to this Privacy Policy</h2>
        <p>Our Privacy Policy may undergo updates periodically. We will inform You of any changes by posting the revised Privacy Policy on this page.</p>
        <p>Before the changes take effect, We will notify You through email and/or a prominent notice on Our Service, and we will update the "Last updated" date at the top of this Privacy Policy.</p>
        <p>We recommend that You review this Privacy Policy regularly to stay informed about any modifications. Changes to this Privacy Policy become effective when they are posted on this page</p>
        <br/>
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
        <ul>
        <li>By email: contact@restful-api.dev</li>
        </ul>
      </div>
    </div>
  );
}

export default PrivacyPolicySection