import React from 'react'
import MyHeader from './../../MyHeader.js';
import Menu from '../../Menu/Menu.js';
import Footer from './../../Footer.js';
import TheoryMenu from './../TheoryMenu.js';
import './../styles.scss';
import useScreenWidth from '../../../hooks/useScreenWidth.js';
import {SMALL_SCREEN_WIDTH} from '../../../constants/constants';
import MobileMenu from '../../Menu/MobileMenu.js';
import TheoryMenuMobile from './../TheoryMenuMobile.js';
import { Helmet } from 'react-helmet';
import CookiesPopup from '../../Cookies/CookiesPopup.js';
import AddsBannerFullWidth from '../../AddsBanner/AddsBannerFullWidth.js';

function GetRequestJava() {
    const widthSize = useScreenWidth();
    const PREPARING_A_REQUEST = 'HttpRequest request = HttpRequest.newBuilder()\n\t.GET()\n\t.uri(URI.create("https://api.restful-api.dev/objects/7"))\n\t.build();';
    const SENDING_A_REQUEST = 'HttpResponse<String> response = HttpClient.newHttpClient()\n\t.send(request, HttpResponse.BodyHandlers.ofString());\n\nSystem.out.println(response.statusCode());\nSystem.out.println(response.body());';
    const RESPONSE = '\
    {\n\
        "id": "7",\n\
        "name": "Apple MacBook Pro 16",\n\
        "data": {\n\
          "year": 2019,\n\
          "price": 1849.99,\n\
          "CPU model": "Intel Core i9",\n\
          "Hard disk size": "1 TB"\n\
        }\n\
    }';
    const POJO = 'import com.fasterxml.jackson.annotation.JsonIgnoreProperties;\nimport java.util.Map;\n\n@JsonIgnoreProperties(ignoreUnknown = true)\npublic class MyObject {\n\n\
        private String name;\n\
        private Map<String, Object> data;\n\n\
        public String getName() {\n\
            return name;\n\
        }\n\n\
        public void setName(String name) {\n\
            this.name = name;\n\
        }\n\n\
        public Map<String, Object> getData() {\n\
            return data;\n\
        }\n\n\
        public void setData(Map<String, Object> data) {\n\
            this.data = data;\n\n\
        }\n\n\
        @Override\n\
        public String toString() {\n\
            return "MyObject{" +\n\
                "name=\'" + name + \'\\\'\' +\n\
                ", data=" + data +\n\
                \'}\';\n\
            }\n\n\
}\
    ';
    const DEPENDENCY = "<dependency>\n\t<groupId>com.fasterxml.jackson.core</groupId>\n\t<artifactId>jackson-databind</artifactId>\n\t<version>2.13.1</version>\n</dependency>";
    const OBJECT_MAPPER = "ObjectMapper objectMapper = new ObjectMapper();\nMyObject myObject = objectMapper.readValue(response.body(), MyObject.class);";
    const ALL_CODE = 'import com.fasterxml.jackson.databind.ObjectMapper;\n\nimport java.net.URI;\nimport java.net.http.HttpClient;\nimport java.net.http.HttpRequest;\nimport java.net.http.HttpResponse;\n\npublic class Main {\n\n\
    public static void main(String[] args) throws Exception {\n\n\
        HttpRequest request = HttpRequest.newBuilder()\n\t\t.GET()\n\t\t.uri(URI.create("https://api.restful-api.dev/objects/7"))\n\t\t.build();\n\n\
        HttpResponse<String> response = HttpClient.newHttpClient()\n\t\t.send(request, HttpResponse.BodyHandlers.ofString());\n\n\
        ObjectMapper objectMapper = new ObjectMapper();\n\
        MyObject myObject = objectMapper.readValue(response.body(), MyObject.class);\n\n\
        System.out.println("Response: " + myObject.toString());\n\n\
    }\n\
}\
    ';

    return (   
        <div className='theory'>
            <Helmet>
                <link rel="canonical" href="https://restful-api.dev/send-a-get-request-java" />
                <meta name='description' content='A Tutorial on how to send the HTTP GET request in Java to the REST API and fetch a response as an object'></meta>
                <title>Send a GET request in Java</title>
            </Helmet> 
            <div className="body">
                {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
                <MyHeader/>
                <div className='theory-page'>
                    {widthSize >= SMALL_SCREEN_WIDTH ? <TheoryMenu/> : null}
                  
                    <div className='theory-content-main'>
                        <div className="theory-content">
                            <br/>
                            <h2 style={{textAlign: 'left'}}>Send a GET request in Java</h2>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>What is a GET Request?</h3>
                            <p>A GET Request is one of the most popular HTTP methods which is used to request data from a server. A GET request does not modify the data and only retrieves it, that's why it is considered to be safe and in some cases can be cached since multiple identical requests should have the same effect as a single request. </p>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>1. How to send a GET request in Java?</h3>
                            <h3 style={{paddingTop: '10px'}}>1.1. Preparing a request</h3>
                            <pre>
                                <code>
                                    <p>
                                    {PREPARING_A_REQUEST}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>In order to represent a request that we are going to send, we can leverage the HttpRequest object which was introduced in Java 11 version.</p>
                            <p>Depending on the REST API to which you are going to send a GET request, you might need to provide some extra configurations, like for example an authorization token which might be part of the Request Headers if REST API requires it. But in our case, we don't require any authorization tokens and we just simply provide a request type (GET) and the request URL ("https://api.restful-api.dev/objects/7"). P.S. number '7' which is part of this URL represents a unique ID of a resource that we are going to request.</p>
                            <br/>
                            <AddsBannerFullWidth/>
                            <br/>
                            <h3>1.2. Sending a request and printing a Response</h3>
                            <pre>
                                <code>
                                    <p>
                                    {SENDING_A_REQUEST}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>The GET request now can be sent using a HttpClient which was also introduced in Java 11 version. By storing a response as a variable, we can print the response code and a response body. If the response code which we got is equal to 200, it would mean that our request was successful, if we got something else, it would mean that there was a problem with a request.</p>
                            <p>If the request was successful, the response.body() would represent the resource that we have requested from the REST API. In this case, the resource with unique ID = 7 looks like this:</p>
                            <pre>
                                <code>
                                    <p>
                                    {RESPONSE}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>2. How to convert a response from a request to a Java Object?</h3>
                            <h3 style={{paddingTop: '10px'}}>2.1. Creating an Object</h3>
                            <p>Sending a request is a good thing, but what do you do with a response? Since it's Java and since it is an Object-oriented programming language, most probably you would like to convert a response from a GET request to a POJO (Plain old Java object), right?</p>
                            <p>One of the first steps would be to obviously create this Object which you would be able to leverage later on in any way you want. This Object should obviously represent a response body which you expect from a REST API.</p>
                            <pre>
                                <code>
                                    <p>
                                        {POJO}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>You can notice an import from an external library and also JsonIgnoreProperties annotation, what it all means, and why do we need it?</p>
                            <br/>
                            <h3>2.2. Jackson library</h3>
                            <p>Jackson library is a very useful external library that provides a convenient way to map JSON data to Java objects. In the example of a POJO which we created above, you can see a JsonIgnoreProperties annotation. This annotation would help us to ignore any unknown properties in a JSON string (Response Body type in our case) during deserialization. Imagine that you get a response body with 50 different attributes and some of them are even optional and you are just interested in 5 particular attributes, how would you handle it? Would you create a POJO with all of the 50 different attributes even though you need 5 of them? Would you get some errors during the decentralization if you see unrecognized attributes? Not if you leverage the Jackson annotations properly because they are very useful!</p>
                            <p>Jackson is an external library, so you might need to import it. Here is an example of how to add it to your depedencies:</p>
                            <pre>
                                <code>
                                    <p>
                                        {DEPENDENCY}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <h3>2.3. Object Mapper</h3>
                            <p>Now we are ready to convert our response to a Java Object. For that, we can use an ObjectMapper class which also comes from a Jackson library.</p>
                            <p>If we create a plain instance of ObjectMapper class, we can use this instance to just simply call readValue method and pass the response body and an Object class to which we want to map our response. This is all that we need to do to convert a JSON response to a Java Object!</p>
                            <pre>
                                <code>
                                    <p>
                                        {OBJECT_MAPPER}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>3. Putting everything together</h3>
                            <pre>
                                <code>
                                    <p>
                                        {ALL_CODE}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
                {widthSize < SMALL_SCREEN_WIDTH ? <TheoryMenuMobile/> : null}
                <Footer/>
                <CookiesPopup widthSize={widthSize}/>
            </div>
        </div>  
    );
}

export default GetRequestJava