import React, { useState, useEffect } from 'react';
import './styles.scss';
import SideMenu from './SideMenu';


function MobileMenu() {
  const [open, setOpen] = useState(false);
  const [sticky, setSticky] = useState('');
  const [blackSideMenu, setBlackSideMenu] = useState('black');

  const handleMenuOpen = () => {
    setOpen(true);
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  const [scrollPos, setScrollPos] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPos > 50) {
      setSticky('hide');
    }
    
    if (scrollPos < prevScrollPos) {
      const headerElement = document.querySelector('.app-header');
      if (headerElement.getBoundingClientRect().bottom < 0) {
          setSticky('sticky');
      } else if (headerElement.getBoundingClientRect().bottom < 450) {
          setSticky('hide');
      } else {
          setSticky('');
      }
    }
    setPrevScrollPos(scrollPos);
  }, [scrollPos]);

  const menuClass = sticky;
  const black = blackSideMenu;

  return (
    <div className='header-menu'>
        <nav className={`menu ${menuClass}`}>
        <div className="logo">
              <a href="/">
                <span className='braces'>&#123;</span>
                <span className="rest-api">REST-API</span>
                <span className='braces'>&#125;</span>
                <span className="ready-to-use">
                &nbsp;READY TO USE
                </span>
              </a>
          </div>
        <button aria-label="Menu" className="toggle-button" id='menu' onClick={handleMenuOpen}>
            <div className="toggle-button-line" />
            <div className="toggle-button-line" />
            <div className="toggle-button-line" />
        </button>
        </nav>
        <SideMenu isOpen={open} blackSideMenu={black} onClose={handleMenuClose} /> 
    </div>
  );
};

export default MobileMenu;
