import React from 'react'
import MyHeader from './../../MyHeader.js';
import Menu from '../../Menu/Menu.js';
import Footer from './../../Footer.js';
import TheoryMenu from './../TheoryMenu.js';
import './../styles.scss';
import useScreenWidth from '../../../hooks/useScreenWidth.js';
import {SMALL_SCREEN_WIDTH} from '../../../constants/constants';
import MobileMenu from '../../Menu/MobileMenu.js';
import { Helmet } from 'react-helmet';
import CookiesPopup from '../../Cookies/CookiesPopup.js';
import TheoryMenuMobile from './../TheoryMenuMobile.js';
import AddsBannerFullWidth from '../../AddsBanner/AddsBannerFullWidth.js';

function PutRequestJava() {
    const widthSize = useScreenWidth();

    const EXISTING_RESOURCE_EXAMPLE = "{\n\
    \"id\": \"4\",\n\
    \"name\": \"Apple iPhone 11, 64GB\",\n\
    \"data\": {\n\
        \"price\": 389.99,\n\
        \"color\": \"Purple\"\n\
    }\n\
}";

    const PREPARING_A_REQUEST = "String requestBody = \"{\\\"name\\\": \\\"Apple iPhone 11, 64GB\\\", \\\"data\\\": {\\\"price\\\": 400, \\\"color\\\": \\\"Purple\\\"}}\";\n\n\
HttpRequest request = HttpRequest.newBuilder()\n\t\
.PUT(HttpRequest.BodyPublishers.ofString(requestBody))\n\t\
.uri(URI.create(\"https://api.restful-api.dev/objects/4\"))\n\t\
.header(\"Content-Type\", \"application/json\")\n\t\
.build();\
";

    const SENDING_A_REQUEST = "HttpResponse<String> response = HttpClient.newHttpClient()\n\
            .send(request, HttpResponse.BodyHandlers.ofString());\n\n\
System.out.println(response.statusCode());\n\
System.out.println(response.body());\n\
";

const POJO = "import java.util.HashMap;\n\n\
public class Product {\n\n\
        private String name;\n\
        private HashMap<String, Object> data;\n\n\
        public Product(String name, HashMap<String, Object> data) {\n\
            this.name = name;\n\
            this.data = data;\n\
        }\n\n\
        public String getName() {\n\
            return name;\n\
        }\n\n\
        public void setName(String name) {\n\
            this.name = name;\n\
        }\n\n\
        public HashMap<String, Object> getData() {\n\
            return data;\n\
        }\n\n\
        public void setData(HashMap<String, Object> data) {\n\
            this.data = data;\n\
        }\n\
}";

    const DEPENDENCY = "<dependency>\n\t<groupId>com.fasterxml.jackson.core</groupId>\n\t<artifactId>jackson-databind</artifactId>\n\t<version>2.13.1</version>\n</dependency>";

    const OBJECT_MAPPER = "HashMap<String, Object> phoneAttributes = new HashMap<>();\n\
phoneAttributes.put(\"price\", 400);\n\
phoneAttributes.put(\"colour\", \"Purple\");\n\n\
Product product = new Product(\"Apple iPhone 11, 64GB\", phoneAttributes);\n\n\
ObjectMapper objectMapper = new ObjectMapper();\n\
String requestBody = objectMapper.writeValueAsString(product);\n\
";

    const ALL_CODE = "import com.fasterxml.jackson.databind.ObjectMapper;\n\n\
import java.net.URI;\n\
import java.net.http.HttpClient;\n\
import java.net.http.HttpRequest;\n\
import java.net.http.HttpResponse;\n\
import java.util.HashMap;\n\n\
public class Main {\n\n\
    public static void main(String[] args) throws Exception {\n\n\
        HashMap<String, Object> phoneAttributes = new HashMap<>();\n\
        phoneAttributes.put(\"price\", 400);\n\
        phoneAttributes.put(\"colour\", \"Purple\");\n\n\
        Product product = new Product(\"Apple iPhone 11, 64GB\", phoneAttributes);\n\n\
        ObjectMapper objectMapper = new ObjectMapper();\n\
        String requestBody = objectMapper.writeValueAsString(product);\n\n\
        HttpRequest request = HttpRequest.newBuilder()\n\
                .PUT(HttpRequest.BodyPublishers.ofString(requestBody))\n\
                .uri(URI.create(\"https://api.restful-api.dev/objects/4\"))\n\
                .header(\"Content-Type\", \"application/json\")\n\
                .build();\n\n\
        HttpResponse<String> response = HttpClient.newHttpClient()\n\
                .send(request, HttpResponse.BodyHandlers.ofString());\n\n\
        System.out.println(response.statusCode());\n\
        System.out.println(response.body());\n\
    }\n\
}\
    ";

    return (   
        <div className='theory'>
            <Helmet>
                <link rel="canonical" href="https://restful-api.dev/send-a-put-request-java" />
                <meta name='description' content='A Tutorial on how to send the HTTP PUT request in Java to update an existing resource'></meta>
                <title>Send a PUT request in Java</title>
            </Helmet> 
            <div className="body">
                {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
                <MyHeader/>
                <div className='theory-page'>
                    {widthSize >= SMALL_SCREEN_WIDTH ? <TheoryMenu/> : null}
                  
                    <div className='theory-content-main'>
                        <div className="theory-content">
                            <br/>
                            <h2 style={{textAlign: 'left'}}>Send a PUT request in Java</h2>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>What is a PUT Request?</h3>
                            <p>A PUT request is one of the HTTP methods which is used when you want to update an existing resource.</p>
                            <p>The request body that you send as part of a PUT request should contain a complete representation of a resource because the PUT request replaces the existing resource and cannot do a partial update of a resource. If you want to do a partial update, you need to use a <a href="/send-a-patch-request-java">PATCH request method</a> instead.</p>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>1. How to send a PUT request in Java?</h3>
                            <h3 style={{paddingTop: '10px'}}>1.1. Preparing a request</h3>
                            <p>Imagine that we have an online shop that sells some phones and our website is using REST API to fetch and store some data about the phones. Now also imagine that we sell an iPhone 11 for 389.99 as in the example below but we want to increase the price of it from 389.99 to 400. How can we do it using a PUT request?</p>
                            <pre>
                                <code>
                                    <p>
                                    {EXISTING_RESOURCE_EXAMPLE}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>Let's prepare our PUT request for that. Since a request body of a PUT request should contain a complete representation of a resource, we need to pass the whole resource. In the example below we are passing the whole resource with an updated price attribute as a String which represents a JSON since this REST API accepts a request body of a type of JSON.</p>
                            <pre>
                                <code>
                                    <p>
                                    {PREPARING_A_REQUEST}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>After that, we are creating a request using an HttpRequest class (P.S. Java 11+). As part of this request, we are specifying a request type as PUT. Also, we are passing the request URL of a PUT request where number 4 represents a unique ID of a resource that we want to update (P.S. ID 4 is a reserved ID and is used here only for an example purpose, so if you want to properly update a resource using our REST API, please create your own resource using a <a href="/send-a-post-request-java">POST request</a> first).</p>
                            <p>In the end, we are specifying the content type of our request body. One important thing is that the Content-Type header should match the content type which the REST API expects to receive as part of your request and your request body should obviously be of the same type as well.</p>
                            
                            <br/>
                            <AddsBannerFullWidth/>
                            <br/>

                            <h3>1.2. Sending a request</h3>
                            <pre>
                                <code>
                                    <p>
                                    {SENDING_A_REQUEST}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>To send a request we can just use the HttpClient class and the send method from it, simply as that. This method returns a response of type HttpResponse. Once the send method is executed, we can use the HttpResponse variable which was created to print the response code and the response body. The response body should contain a complete representation of an updated resource in case if the request was successful. </p>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>2. How to send a Java Object as request body?</h3>
                            <h3 style={{paddingTop: '10px'}}>2.1. Creating an Object</h3>
                            <p>In section 1. we did a decent job and sent a PUT request and yes, it worked but there is one issue with that approach. The issue is that we have constructed the request body manually by creating a String which represents a JSON. This is not very convenient, is it? Most probably you would want to send some real Java Objects which would represent the resource, right? For that, let's start with creating one first.</p>
                            <pre>
                                <code>
                                    <p>
                                    {POJO}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <h3>2.2. Jackson library</h3>
                            <p>Since Java Objects are not JSONs but our REST API expects a request body to be of type JSON, we would need to convert our Object to JSON and for that, we are going to use an ObjectMapper class which comes from an external library called Jackson. This is a very popular Java library that is extremely powerful when it comes to any work related to JSON processing and this library needs to be imported, that's why let's add a dependency to our project (P.S. add it in "pom.xml" if you are using Maven or in "build.gradle" if you are using Gradle).</p>
                            <pre>
                                <code>
                                    <p>
                                    {DEPENDENCY}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <h3>2.3. Object Mapper</h3>
                            <pre>
                                <code>
                                    <p>
                                    {OBJECT_MAPPER}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <p>Now we can simply create a new product using our custom Java Object that represents our resource and we can populate it with the same data to as in section 1.1. If after that we would create an instance of ObjectMapper class and would call a writeValueAsString method where the parameter is our Object, this would convert our Object to a String which represents a JSON. Very simple and is exactly what we need to send a proper PUT request!</p>
                            <br/>
                            <h3 style={{fontSize: '135%'}}>3. Putting everything together</h3>
                            <pre>
                                <code>
                                    <p>
                                        {ALL_CODE}
                                    </p>
                                </code>
                            </pre>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>  
                </div>
                {widthSize < SMALL_SCREEN_WIDTH ? <TheoryMenuMobile/> : null}
                <Footer/>
                <CookiesPopup widthSize={widthSize}/>
            </div>
        </div>  
    );
}

export default PutRequestJava