import React from 'react';

export const RequestInfo = (props) => {

  return (
      <div>
        <h3>Request</h3>
        {props.requestUrlHref &&
        <a className="request-url-href" href={props.requestUrlHref} target="_blank" rel="noopener noreferrer">
          <span className="request-url">
            {props.requestUrl} 
          </span>
        </a> 
        }
        {!!!props.requestUrlHref &&
          <span className="request-url">
              {props.requestUrl} 
          </span>
        }
        {props.requestJson &&
          <div>
            <pre>
                <code>
                  <p>
                    {JSON.stringify(props.requestJson, null, 3)}
                  </p>
                </code>
            </pre>
          </div>
        }
      </div>
  )
}

export default RequestInfo