import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

function TheoryMenu() {

    return (   
        <div className='theory-menu'>
                <br/>
                <br/>
                <br/>
                <br/>
                <h3>LEARN REST</h3>
                <ul className="theory-menu-ul">
                    <li className="theory-menu-item"><Link className="theory-menu-link" to="/rest-fundamentals">Rest Fundamentals</Link></li>
                    <li className="theory-menu-item"><Link className="theory-menu-link" to="/">Send a Request to our REST API</Link></li>
                    <li className="theory-menu-item"><Link className="theory-menu-link" to="/rest-api-history">REST API History</Link></li>
                </ul>
                <br/>
                <br/>
                <h3>TUTORIALS</h3>
                <ul className="theory-menu-ul">
                    <li className="theory-menu-item"><Link className="theory-menu-link" to="/send-a-get-request-java">Send a GET request in Java</Link></li>
                    <li className="theory-menu-item"><Link className="theory-menu-link" to="/send-a-post-request-java">Send a POST request in Java</Link></li>
                    <li className="theory-menu-item"><Link className="theory-menu-link" to="/send-a-put-request-java">Send a PUT request in Java</Link></li>
                    <li className="theory-menu-item"><Link className="theory-menu-link" to="/send-a-patch-request-java">Send a PATCH request in Java</Link></li>
                    <li className="theory-menu-item"><Link className="theory-menu-link" to="/send-a-delete-request-java">Send a DELETE request in Java</Link></li>
                </ul>
                <br/>
                <br/>
                <br/>
                <br/>
        </div>  
    );
}

export default TheoryMenu