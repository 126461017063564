import React from 'react'
import MyHeader from './../MyHeader.js';
import Footer from './../Footer.js';
import PrivacyPolicySection from './PrivacyPolicySection.js';
import Menu from '../Menu/Menu.js';
import useScreenWidth from '../../hooks/useScreenWidth.js';
import {SMALL_SCREEN_WIDTH} from '../../constants/constants';
import MobileMenu from '../Menu/MobileMenu.js';
import CookiesPopup from '../Cookies/CookiesPopup.js';

function PrivacyPolicy() {

    const widthSize = useScreenWidth();

    return (   
        <div>
            <div className="body">
                {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
                <MyHeader/>
                <PrivacyPolicySection widthSize={widthSize}/>
                <Footer/>
                <CookiesPopup widthSize={widthSize}/>
            </div>
        </div>  
    );
}

export default PrivacyPolicy