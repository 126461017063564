import React from 'react'
import './styles.scss';
import header from '../res/header.png';

function MyHeader() {

  return (
    <div className="app-header">
      <a href="/">
        <h1>
          <div className="header-title">
            <img src={header} alt="Free public REST API"></img>
          </div>
        </h1>
      </a>
      <h4>Real REST API which is ready to handle your HTTP requests 24/7 for free. Can be used for your demo projects, testing, learning or even educating someone else. This REST API supports main HTTP methods such as GET, POST, PUT, DELETE and PATCH.</h4>
    </div>
  )
}

export default MyHeader