import React from 'react';

export const ResponseInfo  = (props) => {

    const json = props.responseJson;

    return (
        <div>
          <h3>Response</h3>
          <span className="status-code">200</span>
          <div>
            <pre>
                <code>
                    <p>
                      {JSON.stringify(json, null, 3)}
                    </p>
                </code>
            </pre>
          </div>
        </div>
      )
}

export default ResponseInfo