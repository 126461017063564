import React from 'react'
import MyHeader from './../MyHeader.js';
import Menu from '../Menu/Menu.js';
import Footer from './../Footer.js';
import './../../App.css';
import useScreenWidth from '../../hooks/useScreenWidth.js';
import {SMALL_SCREEN_WIDTH as SMALL_SCREEN_WIDTH} from '../../constants/constants';
import MobileMenu from '../Menu/MobileMenu.js';

function NotFoundPage() {

    const widthSize = useScreenWidth();

    return (      
        <div className="body">
            {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
            <MyHeader/>
            <h2>404 - PAGE NOT FOUND</h2>
            <hr/>
            <p>The Page you are looking for doesn't exist or an other error occured.</p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Footer/>
        </div>
    );
}

export default NotFoundPage