import React from 'react'
import restApi from '../../../res/REST-API.png';
import AddsBannerFullWidth from '../../AddsBanner/AddsBannerFullWidth';
import AddsBanner from '../../AddsBanner/AddsBanner';

function RestFundamentals() {

    return (   
        <div className='theory-content-main'>
            <div className="theory-content">
            
                <div id="rest-api">
                <br/>
                <h2 id="rest" style={{textAlign: 'left'}}>REST fundamentals</h2>
                    <br/>
                    <h3 style={{fontSize: '135%'}}>1. What is REST API?</h3>
                    <p>To understand what is a <strong>REST API</strong>, let’s, first of all, understand what is an API. An API stands for an application programming interface. It defines how applications or devices can connect to and communicate with each other. 
                    <br/><br/>
                    A REST (Representational State Transfer) API is an architectural style for an API that uses HTTP (Hypertext Transfer Protocol) request methods to access and manipulate data over the Internet. The most popular HTTP request methods (which are explained below) are <strong>GET, POST, PUT, DELETE, PATCH, HEAD, TRACE, CONNECT</strong> and <strong>OPTIONS</strong>. 
                    <br/><br/>
                    
                    One of the examples of when REST APIs are used is when we need to expose back-end systems and data to front-end developers in a standardized format. That's why REST APIs architecture is vital when it comes to building web services that are consumed by a wide range of clients such as browsers, desktop applications and mobile devices.                       
                    </p>
                    <br/>
                    <div className='rest-fundamentals-img'>
                        <img src={restApi} alt = "REST API flow image"></img>
                    </div>
                    
                </div>

                <div>
                    <br/>
                    <h3 style={{fontSize: '135%'}}>2. HTTP Methods</h3>
                    <p>
                    HTTP (Hypertext Transfer Protocol) is a protocol that allows communication between clients and servers on the World Wide Web. There exist several dozen different HTTP Methods that can be used for different purposes. The main 9 most popular HTTP Methods are explained below.
                    </p>
                    <br/>
                </div>
                
                <div id="get">
                    <br/>
                    <h3>2.1. GET Request</h3>
                    <p>
                        The GET request is one of the HTTP methods which in simple words is in charge of grabbing data from a data source. The response from a GET request can contain data such as a list of items, a single item, or even just a status message.<br/><br/>A GET request is a safe and idempotent method, meaning that it can be repeated multiple times without having any side effects because it should only retrieve data, not modify it.
                    </p>
                    <br/>
                </div>
                
                <div id="post">
                    <br/>
                    <h3>2.2. POST Request</h3>
                    <p>
                        The POST request is used to send data to the server from a client to create a new resource. The data which is sent as part of a POST request is encoded in the body of the request and is not visible in the URL, unlike with a GET request.
                    </p>
                    <br/>
                </div>

                <AddsBannerFullWidth/>
                
                <div id="put">
                    <br/>
                    <h3>2.3. PUT Request</h3>
                    <p>
                        With help of the PUT request, you can update an existing resource. One important thing to keep in mind when you are updating an existing resource via PUT request is that the request body of a PUT request should contain a complete representation of the resource. Even if you want to update one of the several fields of an existing resource, you need to provide a complete representation of the resource and pass it as a request body. Otherwise, the missing fields will be set to NULL, or in some other cases, the request just would fail. If you want to do a partial update of a resource, look at the PATCH HTTP method.
                    </p>
                    <br/>
                </div>
                
                <div id="delete">
                    <br/>
                    <h3>2.4. DELETE Request</h3>
                    <p>
                    The DELETE request can be used when you want to delete an existing resource from the server. Usually, you specify a resource that you want to delete by providing an ID of a resource as part of the URL parameter.
                    </p>
                    <br/>
                </div>
                
                <div id="patch">
                    <br/>
                    <h3>2.5. PATCH Request</h3>
                    <p>
                        Similar to a PUT HTTP request, a PATCH request can be used to update an existing resource. However, the difference between PUT and PATCH is that when sending a PUT request, the body of a request should contain a complete representation of the resource but with a PATCH request you can provide a partial representation of the resource.
                    </p>
                    <br/>
                </div>

                <div id="head">
                    <br/>
                    <h3>2.6. HEAD Request</h3>
                    <p>HTTP HEAD method is used to fetch the request headers that would be returned if a corresponding GET request was made. In other words, the HEAD method is the same as the GET method with the only difference being that it doesn't return the response body when the request was made. 
                    <br/><br/>
                    The HEAD method can be very useful because it can save you some bandwidth in situations when you only need to retrieve some metadata about the resource without retrieving the actual resource as part of the response body. The metadata returned by a HEAD request can be used to validate the information about the resource such as Content-Length of the resource, Content-Type, Last-Modified date, etc. 
                    <br/><br/>
                    This metadata from the headers can be very handy in situations when you for example just want to check whether the resource actually exists before fetching it or maybe when you just want to see when the resource was modified last time (P.S. imagine if a resource is a big file and you just need to check the date but you don't want to download it).
                    </p>
                    <br/>
                </div>

                <div id="trace">
                    <br/>
                    <h3>2.7. TRACE Request</h3>
                    <p>HTTP TRACE request can be used for debugging purposes when you want to determine what is exactly happening with your HTTP requests. When the TRACE request is sent, the web server would normally echo the exact content of an HTTP request back to the client.
                    <br/><br/>
                    However, the TRACE requests should not be enabled in a production environment because in some scenarios they potentially can reveal some sensitive information about the server.
                    </p>
                    <br/>
                </div>

                <div id="connect">
                    <br/>
                    <h3>2.8. CONNECT Request</h3>
                    <p>HTTP CONNECT request method can be used in order to establish a network connection between a client and a server to create a secure tunnel. Once the connection is established, the client and server can communicate with each other directly and forward data packets to each other.</p>
                    <br/>
                </div>

                <div id="options">
                    <br/>
                    <h3>2.9. OPTIONS Request</h3>
                    <p>HTTP OPTIONS request method can be used to request the available communication options from the server for the target resource. 
                    <br/><br/>
                    When a client sends an OPTIONS request to a server, the server would normally include the list of the allowed HTTP methods for the target resource as part of the "Allow" header in the response.
                    </p>
                    <br/>
                </div>
                <br/>
                <br/>
                
            </div>
        </div>
    );
}

export default RestFundamentals