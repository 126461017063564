export const SMALL_SCREEN_WIDTH = 900;
export const MOBILE_SCREEN_WIDTH = 460;

const listOfObjects = [{"id":"1","name":"Google Pixel 6 Pro","data":{"color":"Cloudy White","capacity":"128 GB"}},{"id":"2","name":"Apple iPhone 12 Mini, 256GB, Blue","data":null},{"id":"3","name":"Apple iPhone 12 Pro Max","data":{"color":"Cloudy White","capacity GB":512}},{"id":"4","name":"Apple iPhone 11, 64GB","data":{"price":389.99,"color":"Purple"}},{"id":"5","name":"Samsung Galaxy Z Fold2","data":{"price":689.99,"color":"Brown"}},{"id":"6","name":"Apple AirPods","data":{"generation":"3rd","price":120}},{"id":"7","name":"Apple MacBook Pro 16","data":{"year":2019,"price":1849.99,"CPU model":"Intel Core i9","Hard disk size":"1 TB"}},{"id":"8","name":"Apple Watch Series 8","data":{"Strap Colour":"Elderberry","Case Size":"41mm"}},{"id":"9","name":"Beats Studio3 Wireless","data":{"Color":"Red","Description":"High-performance wireless noise cancelling headphones"}},{"id":"10","name":"Apple iPad Mini 5th Gen","data":{"Capacity":"64 GB","Screen size":7.9}},{"id":"11","name":"Apple iPad Mini 5th Gen","data":{"Capacity":"254 GB","Screen size":7.9}},{"id":"12","name":"Apple iPad Air","data":{"Generation":"4th","Price":"419.99","Capacity":"64 GB"}},{"id":"13","name":"Apple iPad Air","data":{"Generation":"4th","Price":"519.99","Capacity":"256 GB"}}];
const listOfObjectsByIds = [{"id":"3","name":"Apple iPhone 12 Pro Max","data":{"color":"Cloudy White","capacity GB":512}},{"id":"5","name":"Samsung Galaxy Z Fold2","data":{"price":689.99,"color":"Brown"}},{"id":"10","name":"Apple iPad Mini 5th Gen","data":{"Capacity":"64 GB","Screen size":7.9}}];
const singleObject = { "id": "7", "name": "Apple MacBook Pro 16", "data": { "year": 2019, "price": 1849.99, "CPU model": "Intel Core i9", "Hard disk size": "1 TB" }};
const addObjectRequest = {"name":"Apple MacBook Pro 16","data":{"year":2019,"price":1849.99,"CPU model":"Intel Core i9","Hard disk size":"1 TB"}};
const addObjectResponse = {"id":"7","name":"Apple MacBook Pro 16","data":{"year":2019,"price":1849.99,"CPU model":"Intel Core i9","Hard disk size":"1 TB"}, "createdAt": "2022-11-21T20:06:23.986Z"};
const updateObjectViaPUTRequest = {"name":"Apple MacBook Pro 16","data":{"year":2019,"price":2049.99,"CPU model":"Intel Core i9","Hard disk size":"1 TB", "color": "silver"}}; 
const updateObjectViaPUTResponse = {"id":"7", "name":"Apple MacBook Pro 16","data":{"year":2019,"price":2049.99,"CPU model":"Intel Core i9","Hard disk size":"1 TB", "color": "silver"}, "updatedAt": "2022-12-25T21:08:41.986Z"};
const updateObjectViaPATCHRequest = {"name": "Apple MacBook Pro 16 (Updated Name)"};
const updateObjectViaPATCHResponse = {"id":"7","name":"Apple MacBook Pro 16 (Updated Name)","data":{"year":2019,"price":1849.99,"CPU model":"Intel Core i9","Hard disk size":"1 TB"}, "updatedAt": "2022-12-25T21:09:46.986Z"};
const deleteObject = {"message": "Object with id = 6, has been deleted."};

export const ENDPOINTS_INFO = [
    { id: 1, requestMethodName: "GET", requestMethodDescription: "List of all objects", requestUrl: "https://api.restful-api.dev/objects", responseJson: listOfObjects, requestUrlHref: "https://api.restful-api.dev/objects" },
    { id: 2, requestMethodName: "GET", requestMethodDescription: "List of objects by ids", requestUrl: "https://api.restful-api.dev/objects?id=3&id=5&id=10", responseJson: listOfObjectsByIds, requestUrlHref: "https://api.restful-api.dev/objects?id=3&id=5&id=10" },
    { id: 3, requestMethodName: "GET", requestMethodDescription: "Single object", requestUrl: "https://api.restful-api.dev/objects/7", responseJson: singleObject, requestUrlHref: "https://api.restful-api.dev/objects/7"},
    { id: 4, requestMethodName: "POST", requestMethodDescription: "Add object", requestUrl: "https://api.restful-api.dev/objects", responseJson: addObjectResponse, requestJson: addObjectRequest},
    { id: 5, requestMethodName: "PUT", requestMethodDescription: "Update object", requestUrl: "https://api.restful-api.dev/objects/7", responseJson: updateObjectViaPUTResponse, requestJson: updateObjectViaPUTRequest },
    { id: 6, requestMethodName: "PATCH", requestMethodDescription: "Partially update object", requestUrl: "https://api.restful-api.dev/objects/7", responseJson: updateObjectViaPATCHResponse, requestJson: updateObjectViaPATCHRequest },
    { id: 7, requestMethodName: "DELETE", requestMethodDescription: "Delete object", requestUrl: "https://api.restful-api.dev/objects/6", responseJson: deleteObject }
];

const JS_CODE = 'var xhr = new XMLHttpRequest();\n\
var requestUrl = "https://api.restful-api.dev/objects/4";\n\
xhr.open("GET", requestUrl, true); \n\
xhr.onload = function(){\n\
    console.log(xhr.responseText);\n\
    // Handle data\n\
};\n\
xhr.send()\;';

const JS_CODE_RESPONSE_1 = '{\n\
  "id": 4,\n\
  "name": "Apple iPhone 11, 64GB",\n\
  "data": {\n\
     "price": 389.99,\n\
     "color": "Purple"\n\
  }\n\
}';

const JS_CODE_RESPONSE_2 = '{\n\
  "id": 5,\n\
  "name": "Samsung Galaxy Z Fold2",\n\
  "data": {\n\
     "price": 689.99,\n\
     "color": "Brown"\n\
  }\n\
}';

const JAVA_CODE = 'String body = "{\\"name\\": \\"Apple iPad Air\\", \\"data\\": { \\"Generation\\": \\"4th\\", \\"Price\\": \\"519.99\\", \\"Capacity\\": \\"256 GB\\" }}";\n\
URL url = new URL("https://api.restful-api.dev/objects");\n\
HttpsURLConnection conn = (HttpsURLConnection) url.openConnection();\n\
conn.setRequestMethod("POST");\n\
conn.setDoOutput(true);\n\
conn.setRequestProperty("Content-Type", "application/json");\n\
conn.setRequestProperty("User-Agent", "Mozilla/5.0");\n\n\
try (DataOutputStream dos = new DataOutputStream(conn.getOutputStream())) {\n\
    dos.writeBytes(body);\n\
}\n\n\
try (BufferedReader bf = new BufferedReader(new InputStreamReader(conn.getInputStream()))) {\n\
    String line;\n\
    while ((line = bf.readLine()) != null) {\n\
        System.out.println(line);\n\
    }\n\
}';

const JAVA_CODE_RESPONSE = '{\n\
    "id": 13,\n\
    "name": "Apple iPad Air",\n\
    "data": {\n\
       "Generation": "4th",\n\
       "Price": "519.99",\n\
       "Capacity": "256 GB"\n\
    },\n\
    "createdAt": "2022-11-21T20:06:23.986Z"\n\
}';

const PYTHON_CURRENT_DATA_OBJECT = '{\n\
  "id": 6,\n\
  "name": "Apple AirPods",\n\
  "data": {\n\
     "generation": "3rd",\n\
     "price": 120\n\
  }\n\
}';

const PYTHON_CODE = "import requests\n\
import json\n\n\
headers = {\"content-type\": \"application/json\"}\n\
payload = json.dumps({ \"name\": \"Apple AirPods\", \"data\": { \"color\": \"white\", \"generation\": \"3rd\", \"price\": 135}})\n\
requestUrl = \"https://api.restful-api.dev/objects/6\"\n\
r = requests.put(requestUrl, data=payload, headers=headers)\n\n\
print(r.content)";

const PYTHON_CODE_RESPONSE = '{\n\
  "id": 6,\n\
  "name": "Apple AirPods",\n\
  "data": {\n\
     "color": "white",\n\
     "generation": "3rd",\n\
     "price": 135\n\
  }\n\
  "updatedAt": "2022-11-21T20:06:23.986Z"\n\
}';


export const GETTING_STARTED = [
    {code: JS_CODE, response1: JS_CODE_RESPONSE_1, response2: JS_CODE_RESPONSE_2},
    {code: JAVA_CODE, response: JAVA_CODE_RESPONSE},
    {currentDataObject: PYTHON_CURRENT_DATA_OBJECT, code: PYTHON_CODE, response: PYTHON_CODE_RESPONSE}
];

