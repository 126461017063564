import React from 'react'
import MyHeader from './../MyHeader.js';
import Footer from './../Footer.js';
import './../../App.css';
import './styles.scss';
import useScreenWidth from '../../hooks/useScreenWidth.js';
import Menu from '../Menu/Menu.js';
import { Helmet } from 'react-helmet';
import MobileMenu from '../Menu/MobileMenu.js';
import CookiesPopup from '../Cookies/CookiesPopup.js';
import {SMALL_SCREEN_WIDTH} from '../../constants/constants';

function About() {

    const widthSize = useScreenWidth();

    return (      
        <div className="body">
            <Helmet>
                <link rel="canonical" href="https://restful-api.dev/about" />
                <meta name='description' content='Welcome to restful-api.dev - the ultimate platform for developers, learners, and enthusiasts in the world of software development! Explore a vast collection of learning materials centered around RESTful APIs and leverage our public REST API for effortless HTTP requests'></meta>
                <title>About Us</title>
            </Helmet> 
            {widthSize <= SMALL_SCREEN_WIDTH ? (<MobileMenu/>) : (<Menu/>)}
            <MyHeader/>
            <h2>About us</h2>
            <hr/>
            <div className="about-text">
                
                <h2>About us</h2>
                <p>
                    Welcome to restful-api.dev!
                    At restful-api.dev, we are passionate about empowering developers, learners, and enthusiasts with the tools they need to succeed in the world of software development. Our mission is to provide a seamless and reliable platform where you can explore, experiment, and learn without any barriers. 
                </p>
                <p>
                    On our platform, you'll discover an extensive collection of learning materials, all centered around the world of RESTful APIs. Moreover, you can leverage our public REST API to effortlessly send your HTTP requests. 
                </p>
                <p>
                    Our public REST API offers significant value to a diverse range of users. Whether you are a front-end developer seeking to avoid UI delays caused by back-end changes, a newcomer eager to learn and practice REST API concepts, someone looking to rapidly build a demo project, or even a university professor aiming to demonstrate real HTTP requests to your students, our REST API is a valuable resource for you! It caters to various needs and provides the tools and support to help you succeed in your endeavors and it is also FREE!
                </p>
              
                <h2>Who We Are</h2>
                <p>
                    This service was created by a single passionate developer who firmly believes in the power of knowledge sharing and continuous learning. My journey started with a vision to develop a real public REST API, offering developers a robust playground to test, build, and refine their skills. Additionally, I aimed to create a wealth of supporting materials accessible to all, enriching the learning experience for everyone.
                </p>
            
                <h2>Our REST API</h2>
                <p>We're dedicated to serving the community with a valuable and user-friendly resource. Our feature-rich and free REST API is available round-the-clock to meet your needs. Whether you're an experienced developer, a student, or a beginner in the REST API world, our platform is designed just for you. Don't wait, send your HTTP request today!</p>
            </div>
            <Footer/>
            <CookiesPopup widthSize={widthSize}/>
        </div>
    );
}

export default About